import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

// Import icons
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TailSpin } from "react-loading-icons";
import { useBrowseSitepages } from "../../utils/api/functions/sitepages";
//Import components
import { postGlobalSearch } from "../../utils/api/functions/misc";
import FeaturedPages from "./FeaturedPages";

const SitepageSearchPreview = ({ items = [], show, _ref=null }) => {

  const truncate = (input) =>
    input?.length > 160 ? `${input.substring(0, 150)}...` : input;
  
  console.log("ITEMS: ", items)
  if(items.length === 0) {
    return(
    <div 
      style={{
        color: 'black',
        width: '100%', 
        height: '100%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      No results
    </div>
    )
  }

  return (
    <>
      {items.map((val) => {
        return (
          <Link to={`/pages/page/${val.page_id}`}>
            <div className="sitepages-search-preview-item">
              <div className="sitepages-search-preview-item-img">
                <img src={val.image_icon} />
              </div>
              <div className="sitepages-search-preview-item-title">
                <strong>{val.title}</strong>
                <div className="site-pages-search-item-description">
                  {val.body}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};

const SitepagesSearch = () => {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [isSearching, setSearching] = useState();
  const [show, setShow] = useState(false); 
  const searchRef = useRef(null); 

  const handleSearch = async () => {
    setSearching(true); 
    const form_data = new FormData();
    form_data.append("query", query);
    form_data.append("type", "sitepage_page");
    const res = await postGlobalSearch(form_data);
    

    if (res?.body?.result) {
      setResults(() => [...res.body.result]);
    } else {
      setResults(() => []);
    }
    setShow(() => true)
    setSearching(false); 
  };

  const handleQuery = (event) => {
    setResults(() => []);
    setQuery(() => event.target.value);
  };

  useEffect(() => {

    const delaySearchFn = setTimeout(() => {
      if(query.length > 2 && !isSearching) {
        handleSearch();
      }
    }, 1000)

    return () => clearTimeout(delaySearchFn); 
  }, [query])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShow(() => false)
      }
    }; 
    document.addEventListener('mousedown', handleClickOutside); 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); 
    }
  }, [])

  return (
    <div className="sitepages-search-container">
      <h3>Search</h3>
      <hr />
      <div className="sitepages-search-input-container">
        <input onChange={handleQuery}></input>
        {/* <select>
                    <option>All Categories</option>
                    <option>Category 1</option>
                    <option>Category 2</option>
                    <option>Category 3</option>
                </select> */}

        <div>
          {isSearching ? (<TailSpin />) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} style={{cursor: "pointer"}}/>
          )}
          
        </div>
        <div className={`sitepages-search-preview-container ${show ? "show" : ""}`} ref={searchRef}>
          <SitepageSearchPreview
            items={results}
            show={query.length > 0 && results.length > 0}
            ref={searchRef}
          />
        </div> 
        
      </div>
    </div>
  );
};

const ContentCard = ({ title, children }) => {
  return (
    <div className="sitepages-content-card">
      <div className="sitepages-content-card-title">
        <h5>{title}</h5>
      </div>
      <hr></hr>
      {children}
    </div>
  );
};

const SitepageSmDisplay = ({ data, count, count_type }) => {
  return (
    <Link to={`/pages/page/${data.page_id}`}>
      <div className="sitepages-display-sm">
        <img src={data.image} />
        <div className="sitepages-display-description">
          <strong>{data.title}</strong>
          <small>
            {count} {count_type}
          </small>
        </div>
      </div>
    </Link>
  );
};

//Left Column Components
const MostLiked = () => {
  const { data } = useBrowseSitepages({
    limit: "5",
    orderby: "like_count",
  });

  const [pages, setPages] = useState([]);

  useEffect(() => {
    let new_arr = [];
    if (data && data.body && data.body.response) {
      for (let i = 0; i < data.body.response.length; i++) {
        new_arr.push(data.body.response[i]);
        if (i === 4) break;
      }
    }
    setPages(() => new_arr);
  }, [data]);

  return (
    <ContentCard title="Most Liked Pages">
      {pages.length > 0
        ? pages.map((val) => {
            return (
              <SitepageSmDisplay
                data={val}
                count={val.like_count}
                count_type={"likes"}
              />
            );
          })
        : null}
    </ContentCard>
  );
};
const MostFollowed = () => {
  const { data } = useBrowseSitepages({
    limit: "5",
    orderby: "follow_count",
  });

  const [pages, setPages] = useState([]);

  useEffect(() => {
    let new_arr = [];
    if (data && data.body && data.body.response) {
      for (let i = 0; i < data.body.response.length; i++) {
        new_arr.push(data.body.response[i]);
        if (i === 4) break;
      }
    }
    setPages(() => new_arr);
  }, [data]);

  return (
    <ContentCard title="Most Followed Pages">
      {pages.length > 0
        ? pages.map((val) => {
            return (
              <SitepageSmDisplay
                data={val}
                count={val.follow_count}
                count_type={"followers"}
              />
            );
          })
        : null}
    </ContentCard>
  );
};
const RecentlyViewed = () => {
  const { data } = useBrowseSitepages({
    limit: "2",
    orderby: "modified_date",
  });

  const [pages, setPages] = useState([]);

  useEffect(() => {
    let new_arr = [];
    if (data && data.body && data.body.response) {
      for (let i = 0; i < data.body.response.length; i++) {
        new_arr.push(data.body.response[i]);
        if (i === 4) break;
      }
    }
    setPages(() => new_arr);
  }, [data]);

  return (
    <ContentCard title="Recently Viewed">
      {pages.length > 0
        ? pages.map((val) => {
            return (
              <SitepageSmDisplay
                data={val}
                count={val.follow_count}
                count_type={"followers"}
              />
            );
          })
        : null}
    </ContentCard>
  );
};

// Middle Column Components
// const FeaturedPages = ({pages}) => {
//
//
//     const [active, setActive] = useState(null);
//     const [activeIndex, setActiveIndex] = useState(0);

//     const handleChangeSlide = (new_index) => {
//         setActive(pages[new_index]);
//         setActiveIndex(new_index);
//     }

//     useEffect(() => {
//         if(!active && pages[0]) {
//             setActive(pages[0]);
//         }
//     }, [pages])

//     return(
//         <ContentCard
//             title="Featured Pages"
//         >
//         {
//             (active) ? (
//             <>
//                 <div className="featured-pages-carousel">
//                     <div className="col-3">
//                         <img src={active.image} className="featured-pages-carousel-img"/>
//                     </div>
//                     <div className="col">
//                         <p>{active.title}</p>
//                         <p>{active.creation_date}</p>
//                     </div>

//                 </div>
//                 <div className="featured-pages-carousel-actions">
//                     <div className="featured-pages-carousel-actions-view-page">
//                         <Link to={`/pages/page/${active.page_id}`}>
//                             View Page
//                         </Link>
//                     </div>
//                     <div className="featured-pages-carousel-buttons row">
//                         {
//                             pages.map((val, index) => {
//                                 return(

//                                         <FontAwesomeIcon icon={faCircle} className={(activeIndex === index ? ("carousel-button-active") : "")} onClick={()=>{handleChangeSlide(index)}}/>

//                                 )
//                             })
//                         }
//                     </div>
//                 </div>
//             </>
//             )
//             :
//             (
//                 null
//             )
//         }

//         </ContentCard>
//     )
// }

// Right Column Components
const CreateNewPage = () => {
  const navigate = useNavigate();

  return (
    <div className="sitepages-content-card create-page">
      <button
        onClick={() => {
          navigate("/pages/create");
        }}
      >
        <FontAwesomeIcon icon={faPlus} /> Create New Page
      </button>
    </div>
  );
};
const SponsoredPages = () => {
  const { data } = useBrowseSitepages({
    limit: 5,
    orderby: "sponsored",
  });

  const [pages, setPages] = useState([]);

  useEffect(() => {
    let new_arr = [];
    if (data && data.body && data.body.response) {
      for (let i = 0; i < data.body.response.length; i++) {
        let cur = data.body.response[i];
        if (cur.sponsored === 1) {
          new_arr.push(cur);
        }
        //Limit results
        if (i === 4) break;
      }
    }

    if (new_arr.length > 0) setPages(() => new_arr);
  }, [data]);

  return (
    <ContentCard title="Sponsored Pages">
      {pages.length > 0
        ? pages.map((val) => {
            return (
              <SitepageSmDisplay
                data={val}
                count={val.follow_count}
                count_type={"followers"}
              />
            );
          })
        : null}
    </ContentCard>
  );
};

const Sitepages = () => {
  const { data, isLoading } = useBrowseSitepages({
    limit: 20,
  });
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setPages(() => [...data.body.response]);
    }
  }, [data]);

  return (
    <div className="sitepages-home-container">
      <SitepagesSearch />
      <div className="sitepages-home-content-grid">
        <div className="liked-followed-sitepages-columns">
          <MostLiked />
          <MostFollowed />
        </div>
        <div className="sponsored-recently-viewed-sitepages-columns-small-screens" style={{display:"none"}}>
          <CreateNewPage />
          <SponsoredPages />
          <RecentlyViewed />
          {/* <PopularTags />
                    <RecommendedPages /> */}
        </div>
        <div className="featured-pages-container">
          <FeaturedPages pages={pages} isLoading={isLoading} />
          {/* <Categories /> */}
        </div>
        <div className="sponsored-recently-viewed-sitepages-columns">
          <CreateNewPage />
          <SponsoredPages />
          <RecentlyViewed />
          {/* <PopularTags />
                    <RecommendedPages /> */}
        </div>
      </div>
    </div>
  );
};

export default Sitepages;
