// Function to store whether a user has seen the story widget in a cookie 
export const setStoryWidgetSeen = (story_id) => {
    if(!story_id) return; 
    const date = new Date(); 
    // Set the cookie to expire in 1 week
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie = `story_${story_id}_seen=true; expires=${date.toUTCString()}; path=/`;
}
// Function to check if a user has seen the story widget
export const hasStoryWidgetSeen = (story_id) => {
    return document.cookie.includes(`story_${story_id}_seen=true`);
}