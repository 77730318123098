import { faCircle, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import LoadingNotificationLarge from "../../components/loading-placeholders/LoadingNotification";
import { postDeleteNotification, postMarkNotificationRead, postMarkNotificationUnread, useGetNotifications } from "../../utils/api/functions/notification";
import { getMonthAndDate } from "../../utils/parsing";
//Import notification type map
import { map_notification_to_link } from "../../utils/mapping";

const MenuPopup = ({ show, id, handleClose, read_status }) => {

  console.log(read_status);

  const {data, mutate} = useGetNotifications({
    myRequests: 0,
    recentUpdates: 1,
  }); 
  const {toast} = useToast();
  const handleMarkRead = async() => {
    handleClose();
    const res = await postMarkNotificationRead({action_id: id});
    if(res.status_code === 204){
      toast.success("Notification read!");
      mutate();
    }
    else {
      toast.error("Error marking as read");
    }
  }
  const handleDelete = async() => {
    handleClose();
    const res = await postDeleteNotification({action_id: id});
    if(res.status_code === 204) {
      toast.success("Notification deleted!");
      mutate();
    }
    else {
      toast.error("Error deleting notification");
    }
    
  }
  const handleMarkUnread = async() => {
    handleClose();
    const res = await postMarkNotificationUnread({action_id: id});
    if(res.status_code === 204){
      toast.success("Notification unread!");
      mutate();
    }
    else {
      toast.error("Error marking as unread");
    }
  }

  return (
    <div className={`notification-menu ${show ? "show" : ""}`}>
      {
        read_status ? (
          <div className="notification-menu-action" onClick={handleMarkUnread}>Mark Unread</div>
        )
        :
        (
          <div className="notification-menu-action" onClick={handleMarkRead}>Mark Read</div>
        )
      }
      <div className="notification-menu-action" onClick={handleDelete}>Delete</div>
    </div>
  );
};

const GUTTER_SIZE = 10;
const NotificationItem = ({ data, index, style }) => {
  const [showMenu, setShowMenu] = useState(false);
  const item = data[index];

  let mapped_link = map_notification_to_link(item);
  const toggleMenu = () => {
    setShowMenu((old) => !old);
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if(!menuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return() => {
      document.removeEventListener("mousedown", handler);
    }
  });

  const handleClose = () => {
    setShowMenu(false);
  }

  if (mapped_link) {
    return (
      <div
        style={{
          ...style,
          top: style.top + GUTTER_SIZE,
          bottom: style.bottom + GUTTER_SIZE,
        }}
      >
        <div className="notification-item-wrapper">
          <div className="notification-item-actions">
            <div className="notification-item-action-more" ref={menuRef}>
              <FontAwesomeIcon icon={faEllipsis} onClick={toggleMenu} />
              <MenuPopup show={showMenu} id={item.notification_id} handleClose={handleClose} read_status={item.read}/>
            </div>
            <div className="notification-item-action-status">
              <FontAwesomeIcon
                icon={faCircle}
                className={item.read ? "read" : "unread"}
              />
            </div>
          </div>
          <Link to={mapped_link} className="notification-item">
            <div className="notification-item-image">
              <img src={item.subject.image} />
            </div>
            <div className="notification-item-content">{item.feed_title}</div>
            <div className="MonthDate">{getMonthAndDate(item.date)}</div>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="notification-item"
        style={{
          ...style,
          top: style.top + GUTTER_SIZE,
          bottom: style.bottom + GUTTER_SIZE,
        }}
      >
        <div className="notification-item-image">
          <img src={item.subject.image} />
        </div>
        <div className="notification-item-content">{item.feed_title}</div>
        <div className="notification-item-actions">
          <div className="notification-item-action-more">
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
          <div className="notification-item-action-status"></div>
        </div>
      </div>
    );
  }
};

const LoadingListItem = ({ data, index, style}) => {
  return (
    <div
      style={{
        ...style,
        top: style.top + GUTTER_SIZE,
        bottom: style.bottom + GUTTER_SIZE,
      }}
    >
      <LoadingNotificationLarge />
    </div>
  );
}

const NotificationsUpdates = () => {
  const [notifications, setNotifications] = useState([]);
  const { data, isLoading } = useGetNotifications({
    myRequests: 0,
    recentUpdates: 1,
  });

  useEffect(() => {
    if (data && data.body && data.body.recentUpdates) {
      setNotifications(() => [...data.body.recentUpdates]);
    }
  }, [data]);

  if (isLoading && notifications.length === 0) {
    return(
    <div className="notifications-recent-updates notifications-section">
      <div className="notifications-header">Recent Updates</div>
      <div className="notifications-content">
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              width={width}
              itemCount={10}
              itemSize={100}
              
            >
              {LoadingListItem}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
    );
  }

  if (!isLoading && notifications.length === 0) {
    return (
      <div className="notifications-recent-updates notifications-section">
        <div className="notifications-header">Recent Updates</div>
        <div className="notifications-content">
        <div className="notifications-content-message">No Updates...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="notifications-recent-updates notifications-section">
      <div className="notifications-header">Recent Updates</div>
      <div className="notifications-content">
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              width={width}
              itemCount={notifications.length}
              itemSize={100}
              itemData={notifications}
            >
              {NotificationItem}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

const NotificationsRequests = () => {
  const [notifications, setNotifications] = useState([]);
  const { data, isLoading } = useGetNotifications({
    myRequests: 1,
    recentUpdates: 0,
  });

  useEffect(() => {
    if (data && data.body && data.body.myRequests) {
      setNotifications(() => [...data.body.myRequests]);
    }
  }, [data]);

  if (isLoading && notifications.length === 0) {
    return(
    <div className="notifications-recent-updates notifications-section">
      <div className="notifications-header">Recent Updates</div>
      <div className="notifications-content">
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              width={width}
              itemCount={10}
              itemSize={100}
              
            >
              {LoadingListItem}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
    );
  }

  if (!isLoading && notifications.length === 0) {
    return (
      <div className="notifications-my-requests notifications-section">
        <div className="notifications-header">My Requests</div>
        <div className="notifications-content">
          <div className="notifications-content-message">No Friend Requests...</div>
        </div>
      </div>
    );
  }
  return (
    <div className="notifications-my-requests notifications-section">
      <div className="notifications-header">My Requests</div>
      <div className="notifications-content">
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              width={width}
              itemCount={notifications.length}
              itemSize={100}
              itemData={notifications}
            >
              {NotificationItem}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [ show, setShow ] = React.useState(true)
  return (
    <div className="notifications-container">
      <div className="notifications-buttons">
        <button onClick={(()=>setShow(true))}>Recent Updates</button>
        <button onClick={(()=>setShow(false))}>My Requests</button>
      </div>
      {
        show?<NotificationsUpdates />:<NotificationsRequests />
      }
    </div>
  );
};

export default Notifications;
