import {
  faCalendar,
  faLocationPin,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Bars from "react-loading-icons/dist/esm/components/bars";
import { Link, useNavigate } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

const GUTTER_SIZE = 10;

const convertDate = (date) => {
  console.log("DATE: ", date)
  let _date = new Date(date);
  //_date = new Date(_date.getTime() + _date.getTimezoneOffset() * 60000);
  const _formatted_date = `${_date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })} ${_date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit"
  })}`;

  return _formatted_date;
};

const EventsList = ({
  events,
  itemEl = null,
  action_buttons = null,
  isLoading = true,
}) => {
  const _EventItem = ({ index, style }) => {
    const calculated_height = style.height - GUTTER_SIZE;
    const _event = events[index];
    const navigate = useNavigate();

    const _default_actions = (event) => {
      return <div className="default-event-list-item-actions"></div>;
    };
    const ActionButtons = action_buttons || _default_actions;

    const truncate = (input) =>
      input?.length > 45 ? `${input.substring(0, 40)}...` : input;

    return (
      <div
        className="default-event-list-item"
        style={{
          ...style,
          height: calculated_height,
        }}
        onClick={() => {
          navigate(`/events/event/${_event.event_id}`);
        }}
      >
        <img src={_event.image} />
        <div className="default-event-list-item-info">
          <div className="default-event-list-item-title">
            <Link
              to={`/events/event/${_event.event_id}`}
              className="default-link"
            >
              <span>{truncate(_event.title)}</span>
            </Link>
          </div>
          <div className="default-event-list-item-guest-count">
            <FontAwesomeIcon icon={faUsers} />
            {_event.member_count > 1
              ? `${_event.member_count} guests `
              : `${_event.member_count} guest `}
            led by{" "}
            <Link to={`/profile/${_event.parent_id}`} className="default-link">
              <span>{_event.host}</span>
            </Link>
          </div>
          <div className="default-event-list-item-location">
            <FontAwesomeIcon icon={faLocationPin} />
            {_event.location}
          </div>
          <div className="default-event-list-item-start-date">
            <FontAwesomeIcon icon={faCalendar} />
            {convertDate(_event.starttime)}
          </div>
        </div>

        <ActionButtons event={_event} />
      </div>
    );
  };
  const EventItem = itemEl || _EventItem;
  if (isLoading) {
    return (
      <div className="loading-icon-wrapper loading-align-top">
        <Bars stroke="#00BCD4" fill="#00BCD4" />
      </div>
    );
  }

  if (!isLoading && events.length === 0) {
    return <div className="list-placeholder">No events yet</div>;
  }
  return (
    <div>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              className="events-list"
              height={height}
              width={width}
              itemCount={events.length}
              itemSize={height / 5}
            >
              {EventItem}
            </List>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default EventsList;
