import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import AdvertisementStats from "../../components/charts/AdvertisementStats";
import { postDeleteCampaign, postEditCampaign, useGetOwnersAds } from "../../utils/api/functions/communityAds";

const CampaignEditModal = ({ show, onHide, campaign}) => {
  const [name, setName] = useState(campaign.name);
  const {data, mutate} = useGetOwnersAds({}); 

  const {toast} = useToast();

  const handleEdit = (e) => {
    setName(e.target.value);
  }
  const handleSubmit = async() => {
    const res = await postEditCampaign({
      id: campaign.adcampaign_id, 
      name: name
    });

    if(res?.status_code === 200) {
      toast.success('Campaign updated');
      // Optimistic update 
      const updatedCampaigns = data.body.campaigns.map(c => {
        if(c.adcampaign_id === campaign.adcampaign_id) {
          return {
            ...c,
            name: name
          }
        }
        return c;
      });
      mutate({...data, body: {campaigns: updatedCampaigns}}, false);
      onHide();
    }
    else {
      toast.error('Error updating campaign'); 
    }
  }

  return(
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control type="text" value={name} onChange={handleEdit}/>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSubmit}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

const CampaignDeleteConfirmation = ({show, onHide, campaign}) => {
  const {data, mutate} = useGetOwnersAds({});
  const {toast} = useToast(); 

  const handleSubmit = async() => {
    const res = await postDeleteCampaign(campaign.adcampaign_id);

    if(res?.status_code == 200){
      toast.success('Campaign deleted'); 
      // Optimistic update 
      const updatedCampaigns = data.body.campaigns.filter(c => c.adcampaign_id !== campaign.adcampaign_id); 
      mutate({...data, body: {campaigns: updatedCampaigns}}, false); 
      toast.success('Campaign deleted');
      onHide();
    }
    else {
      toast.error('Error deleting campaign'); 
    }

    
  }  

  return(
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this campaign?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSubmit}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

const CampaignRow = ({ campaign, selectedCampaign, handleSelect }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false); 
  const handleOpenEdit = () => {
    setShowEditModal(true); 
  }
  const handleCloseEdit = () => {
    setShowEditModal(false); 
  }
  const handleOpenDelete = () => {
    setShowDelete(true); 
  }
  const handleCloseDelete = () => {
    setShowDelete(false); 
  }

  return (
    <tr className="campaign-row">
      <td>
        <Form.Check 
          checked={selectedCampaign.includes(campaign.adcampaign_id)}
          onChange={() => handleSelect(campaign.adcampaign_id)}
        />
      </td>
      <td>{campaign.name}</td>
      <td>{campaign.ads}</td>
      <td>{campaign.views}</td>
      <td>{campaign.clicks}</td>
      <td>{campaign.CTR}</td>
      <td className="campaign-row-options">
        <span onClick={handleOpenEdit}>Rename</span>|<span onClick={handleOpenDelete}>Delete</span>
      </td>
      <CampaignEditModal
        show={showEditModal}
        onHide={handleCloseEdit}
        campaign={campaign}
      />
      <CampaignDeleteConfirmation
        show={showDelete}
        onHide={handleCloseDelete}
        campaign={campaign}
      />
    </tr>
  );
};

const AdCampaigns = () => {
  const { data, mutate } = useGetOwnersAds({});
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.campaigns) {
      setCampaigns(data.body.campaigns);
      if(selectedCampaign.length === 0){
        setSelectedCampaign([data.body.campaigns[0].adcampaign_id]);
      }
      
    }
  }, [data]);

  const handleSelect = (id) => {
    // If the campaign is already in the selectedCampaign array, remove it
    console.log(selectedCampaign)
    if(selectedCampaign.includes(id)){
      setSelectedCampaign(selectedCampaign.filter(c => c !== id));
    }
    else {
      setSelectedCampaign([...selectedCampaign, id]);
    }
  }

  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">My Campaigns</div>
      <div className="box-content">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                {/* <Form.Check /> */}
              </th>
              <th>Campaign Name</th>
              <th>Ads</th>
              <th>Views</th>
              <th>Clicks</th>
              <th>CTR(%)</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((val, index) => {
              return <CampaignRow key={`campaign-${index}`} campaign={val} selectedCampaign={selectedCampaign} handleSelect={handleSelect}/>;
            })}
          </tbody>
        </Table>
        <AdvertisementStats campaign_id={selectedCampaign}/>
      </div>
    </div>
  );
};

export default AdCampaigns;
