import React, { useEffect, useState } from "react";
import { useGetUserInfo } from "../../utils/api/functions/members";

import { Link } from "react-router-dom";
import { LoadingCircleImage } from "../loading-placeholders/LoadingImages";

const ProfilePreview = () => {
  const [info, setInfo] = useState({});
  const user_id = localStorage.getItem("user_id");
  const { data, mutate, isLoading } = useGetUserInfo({
    user_id: user_id,
  });

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setInfo((old) => data.body.response);
    }
  }, [data]);

  return (
    <div className="home-sidebar-item sidebar-profile-preview">
      <div className="profile-preview-header">Hi {info.displayname}!</div>
      <div className="profile-preview-img-container">
        <Link to={`/profile/${info.user_id}`}>
          {isLoading ? <LoadingCircleImage /> : <img src={info.image_profile}/>}
        </Link>
      </div>
    </div>
  );
};

export default ProfilePreview;
