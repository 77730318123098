import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { useAdStatistics } from '../../utils/api/functions/communityAds';

const chunk_map = {
    "1d": 86400,
    "1w": 604800,
    "1m": 2592000,
    "3m": 7776000,
    "6m": 15552000,
    "1y": 31536000
};

const determineChunk = (start, end, ) => {
    const duration = end - start; // in seconds 
    if (duration <= chunk_map["1w"]*4) {
        return chunk_map["1d"]; // For durations up to 1 week, use daily chunks
    } else if (duration <= chunk_map["1m"]*5) {
        return chunk_map["1w"]; // For durations up to 1 month, use weekly chunks
    } else if (duration <= chunk_map["3m"]*5) {
        return chunk_map["1m"]; // For durations up to 3 months, use monthly chunks
    } else if (duration <= chunk_map["6m"]*5) {
        return chunk_map["3m"]; // For durations up to 6 months, use 3-month chunks
    } else if (duration <= chunk_map["1y"]*5) {
        return chunk_map["6m"]; // For durations up to 1 year, use 6-month chunks
    } else {
        return chunk_map["1y"]; // For longer durations, use yearly chunks
    }
}

const AdvertisementStatsChart = ({campaign_id=null, ad_id=null, start, end}) => {
    const [chartData, setChartData] = useState({
        labels: [], 
        datasets: []
    });

    const { data, isLoading } = useAdStatistics(
        'all', 
        start, 
        end, 
        determineChunk(start, end),
        ad_id,
        campaign_id,
    );

    useEffect(() => {
        if (data?.body) {
            const labels = data.body.labels || [];
            const views = data.body.data.views || [];
            const clicks = data.body.data.clicks || [];

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Views',
                        data: views,
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Clicks',
                        data: clicks,
                        backgroundColor: 'rgba(192, 75, 75, 0.6)',
                        borderColor: 'rgba(192, 75, 75, 1)',
                        borderWidth: 1
                    }
                ]
            });
        }
        console.log("NEW START: ", start)
    }, [data, start, end]);

    // if (isLoading || !chartData) {
    //     return <div>Loading...</div>; // Show a loading state
    // }

    return (
        <div>
            <Line
                data={chartData}
                options={{
                    responsive: true,

                    animation: {
                        duration: 1000, 
                        easing: 'easeInOutQuad'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Advertisement Stats'
                        },
                        legend: {
                            display: true,
                            position: 'top'
                        }
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Time Period'
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Counts'
                            },
                            beginAtZero: true
                        },
                    }
                }}
            />
        </div>
    );
};


const AdvertisementStats = ({ad_id, campaign_id}) => {

    const [startUnix, setStartUnix] = useState(Math.floor(Date.now() / 1000) - 31536000); // Default to 30 days ago
    const [endUnix, setEndUnix] = useState(Math.floor(Date.now() / 1000)); // Default to now
    console.log("Ad ID: ", ad_id, "Campaign ID: ", campaign_id)
    const handleStartDateChange = (e) => {
        console.log(e.target.value);
        const [year, month, day] = e.target.value.split('-').map(Number); 
        const unix = Date.UTC(year, month - 1, day) / 1000; 
        setStartUnix(()=>unix);
    };
    
    const handleEndDateChange = (e) => {
        console.log("End Date: ", e.target.value); // Input value in YYYY-MM-DD format
        const [year, month, day] = e.target.value.split('-').map(Number); // Extract year, month, day
        const unix = Date.UTC(year, month - 1, day) / 1000; // Convert to Unix timestamp (UTC)
        setEndUnix(()=>unix);
    };

    return (
        <div className="advertisement-stats">
            <div className="advertisement-stats-filters">
                <Form.Group>
                    <Form.Label>Start</Form.Label>
                    <Form.Control 
                        type="date" 
                        onChange={handleStartDateChange}
                        value={moment.unix(startUnix).utc().format('YYYY-MM-DD')}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>End</Form.Label>
                    <Form.Control 
                        type="date" 
                        onChange={handleEndDateChange}
                        value={moment.unix(endUnix).utc().format('YYYY-MM-DD')}
                    />
                </Form.Group>
            </div>
            <AdvertisementStatsChart campaign_id={campaign_id} ad_id={ad_id} start={startUnix} end={endUnix}/> 
        </div>
    )
}

export default AdvertisementStats;
