import { faGear, faPaperPlane, faPenToSquare, faUser, faUserPlus, faUserXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import UploadCoverPhoto from "../components/buttons/UploadCoverPhoto";
import { LoadingCircleImage } from "../components/loading-placeholders/LoadingImages";
import { ReportModal } from "../components/widgets/ReportModal";
import UserBlockModal from "../components/widgets/UserBlockModal";
import ProfileSidebar from "../pages/Profile/Sidebar";
import routes from "../settings/routes";
import {
  postAcceptFriendRequest,
  postAddFriend,
  postCancelFriendRequest,
  postRejectFriendRequest,
  postRemoveFriend,
  useGetFriendRequests
} from "../utils/api/functions/friends";
import { useGetUserInfo } from "../utils/api/functions/members";
import { postNewMessage } from "../utils/api/functions/messages";
import { useMessages } from "../utils/context/messages/messagesContext";
import DefaultLayout from "./Default";

import "bootstrap/js/src/modal";
import StoryView from "../components/widgets/StoriesWidget/StoryView";
import { useGetSitestories } from "../utils/api/functions/sitestories";

import "../styles/sass/components/Profile/UserProfile.scss";

const ProfileNav = () => {
  const { id } = useParams();
  useEffect(() => {

    const profile_nav_routes = routes.filter((val) => {
      if (val.name === "Profile") {
        return true;
      }
      return false;
    });
    if (!profile_nav_routes) return;

  }, []);

  return (
    <div className="profile-nav">
      <NavLink
        to={`/profile/${id}/posts`}
        key={"profile-nav-1"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Posts
      </NavLink>
      <NavLink
        to={`/profile/${id}/info`}
        key={"profile-nav-2"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Info
      </NavLink>
      {/* <NavLink
        to={`/profile/${id}/stories`}
        key={"profile-nav-3"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Stories
      </NavLink> */}
      <NavLink
        to={`/profile/${id}/videos`}
        key={"profile-nav-4"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Videos
      </NavLink>
      <NavLink
        to={`/profile/${id}/friends`}
        key={"profile-nav-5"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Friends
      </NavLink>
      <NavLink
        to={`/profile/${id}/albums`}
        key={"profile-nav-6"}
        className="profile-nav-item"
        activeClassName="profile-nav-item active"
      >
        Albums
      </NavLink>
    </div>
  );
};

const ProfileActionDropdown = ({ button_name, subLinks }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const ref = useRef(null);

  const handleToggle = () => {
    setToggleMenu((old) => !old);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setToggleMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  return (
    <div ref={ref} className="profile-action-dropdown" onClick={handleToggle}>
      <div className="profile-action-dropdown-main"><FontAwesomeIcon icon={faGear} /> {button_name}</div>
      <div
        className={`profile-action-dropdown-sub ${toggleMenu ? "active" : ""}`}
      >
        {subLinks.map((val, index) => {
          return (
            <Link
              to={val.link}
              key={index}
              className="profile-action-dropdown-sub-item"
            >
              {val.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const ProfileHeaderUserActions = ({}) => {
  const { id } = useParams();

  const isSelf = id == window.localStorage.getItem("user_id");
  const { data } = useGetUserInfo({
    user_id: id,
  });
  const {data: friend_data, mutate: friend_mutate} = useGetFriendRequests({}); 

  const [friendStatus, setFriendStatus] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [blockStatus, setBlockStatus] = useState(null); 
  const [messageStatus, setMessageStatus] = useState(false); 
  const [showBlockModal, setShowBlockModal] = useState(false); 
  const [showReportModal, setShowReportModal] = useState(false); 

  const { toast } = useToast();
  const {openNewMessage, openMessage} = useMessages(); 
  useEffect(() => {
    if (data && data.body && data.body.response) {
      setProfileData(() => data.body.response);
    }
    if (data && data.body && data.body.gutterMenu) {
      const gutterMenu = data.body.gutterMenu;
      if (gutterMenu.find((o) => o.name === "cancel_request")) {
        setFriendStatus("cancel");
      }
      if (gutterMenu.find((o) => o.name === "add_friend")) {
        setFriendStatus("add");
      }
      if (gutterMenu.find((o) => o.name === "remove_friend")) {
        setFriendStatus("remove");
      }
      if (gutterMenu.find((o) => o.name === "accept_request")) {
        setFriendStatus("accept/reject");
      }
      if (gutterMenu.find(o => o.name === "user_profile_unblock")){
        setBlockStatus(true); 
      } else {
        setBlockStatus(false); 
      }
      if (gutterMenu.find(o => o.name === "user_profile_send_message")){
        setMessageStatus(true); 
      }

    }
  }, [data]);

  const handleRemoveFriend = async () => {
    if (!profileData || !profileData.user_id) return;
    const form_data = new FormData();
    form_data.append("user_id", profileData.user_id);

    const res = await postRemoveFriend(form_data);

    if (res.status_code === 204) {
      toast.success("Friend removed");
      setFriendStatus(() => "add");
    } else {
      toast.error("Something went wrong");
    }
  };
  const handleAddFriend = async () => {
    if (!profileData || !profileData.user_id) return;

    const form_data = new FormData();
    form_data.append("user_id", profileData.user_id);

    const res = await postAddFriend(form_data);

    if (res.status_code === 204) {
      toast.success("Friend request sent");
      setFriendStatus(() => "cancel");
    } else {
      if (res.error_code && res.error_code === "user_already_friend") {
        toast.error("Friend request already sent");
        return;
      }
      toast.error("Something went wrong");
    }
  };
  const handleCancelRequest = async () => {
    if (!profileData || !profileData.user_id) return;

    const form_data = new FormData();
    form_data.append("user_id", profileData.user_id);

    const res = await postCancelFriendRequest(form_data);


    if (res.status_code === 204) {
      toast.success("Friend request cancelled");
      setFriendStatus(() => "add");
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleAcceptRequest = async () => {
    if (!profileData || !profileData.user_id) return; 

    const form_data = new FormData(); 
    form_data.append("user_id", profileData.user_id);
    
    const res = await postAcceptFriendRequest(form_data);
    if (res.status_code === 204){
      toast.success("Friend request accepted"); 
      setFriendStatus(() => "remove");
      friend_mutate(); 
    }
    else {
      toast.error("Something went wrong");
    }
  }
  const handleRejectRequest = async() => {
    if(!profileData || !profileData.user_id) return; 

    const form_data = new FormData();
    form_data.append("user_id", profileData.user_id);

    const res = await postRejectFriendRequest(form_data); 

    if (res.status_code === 204) {
      toast.success("Friend request rejected");
      setFriendStatus(() => "add");
    } else {
      toast.error("Something went wrong");
    }
  }

  const handleNewMessage = async() => {
    const formData = new FormData(); 
    formData.append("toValues", id); 
    formData.append("title", " ");
    formData.append("body", " "); 
    formData.append("react_app", true); 
    const result = await postNewMessage(formData); 
    if (result && result.status_code === 200) {
      console.log("MESSAGE!!: ", result.body.messages)
      openMessage(result.body.messages[0]); 
    }
    else {
      toast.error("Error sending message"); 
    }
  }

  if (isSelf) {
    return (
      <div className="profile-header-user-actions">
        <div className="profile-header-user-actions-link">
          <Link to={`/profile/edit`}><FontAwesomeIcon icon={faPenToSquare} /> Edit Profile</Link>
        </div>
        <ProfileActionDropdown
          button_name="Settings"
          subLinks={[
            {
              name: "General",
              link: "/profile/settings",
            },
            {
              name: "Privacy",
              link: "/profile/settings/privacy",
            },
            {
              name: "Notifications",
              link: "/profile/settings/notifications",
            },
            {
              name: "Subscription",
              link: "/profile/settings/subscriptions",
            },
            {
              name: "Change Password",
              link: "/profile/settings/change-password",
            },
          ]}
        />
      </div>
    );
  } else {
    return (
      <div className="profile-header-user-actions">
        {friendStatus === "cancel" && (
          <Button onClick={handleCancelRequest}><FontAwesomeIcon icon={faUserXmark} /> Cancel Request</Button>
        )}
        {friendStatus === "remove" && (
          <Button onClick={handleRemoveFriend}><FontAwesomeIcon icon={faUserXmark} />  Remove Friend</Button>
        )}
        {friendStatus === "add" && (
          <Button onClick={handleAddFriend}><FontAwesomeIcon icon={faUserPlus} /> Add Friend</Button>
        )}
        {friendStatus === "accept/reject" && (
          <>
            <Button onClick={handleAcceptRequest}><FontAwesomeIcon icon={faUser} /> Accept Request</Button>
            <Button onClick={handleRejectRequest}><FontAwesomeIcon icon={faUserXmark} /> Reject Request</Button>
          </>
        )}
        {
          messageStatus && (
            <Button onClick={
              ()=>{
                handleNewMessage();
              }
            }><FontAwesomeIcon icon={faPaperPlane} /> Send Message</Button>
          )
        }
        <DropdownButton className="profile-header-user-block-report">
        {
          blockStatus !== null && (
            <>
              <Dropdown.Item onClick={()=>{setShowBlockModal(true)}}>{blockStatus ? "Unblock" : "Block"}</Dropdown.Item>
              <UserBlockModal 
                show={showBlockModal}
                handleClose={()=>{setShowBlockModal(false)}}
                isBlocked={blockStatus}
                user_id={profileData.user_id}
              />
            </>
          )
        }
        <Dropdown.Item onClick={()=>setShowReportModal(true)}>Report</Dropdown.Item>
        <ReportModal 
          show={showReportModal}
          onHide={()=>setShowReportModal(false)}
          type="user"
          id={profileData.user_id}
        />
        </DropdownButton>
      </div>
    );
  }
};

const ProfileImage = ({src, user_id}) => {
  const {data, error, mutate} = useGetSitestories({limit: 10}); 
  const [story, setStory] = useState(null); 
  const [showStory, setShowStory] = useState(false); 
  const [activeStory, setActiveStory] = useState(false); 

  const handleShowStory = () => {
    setShowStory(true); 
  }
  const handleHideStory = () => {
    setShowStory(false); 
  }

  useEffect(() => {
    if(data && data.body && data.body.response) {
      console.log("Profile stories: ", data.body.response);
      setStory(data.body.response[0] || null); 
    }
  }, [data])

  useEffect(() => {
    if(story !== null) {
      setActiveStory(true); 
    }
  }, [story])

  return(
  <>
    <img
      src={src}
      onError={({ target }) => {
        target.onerror = null 
        target.src = "/no_profile_pic.png"
      }}
      className={activeStory ? "active-story" : ""}
      style={{cursor: story !== null ? "pointer" : ""}}
      onClick={handleShowStory}
    />
    <StoryView
      show={showStory}
      onHide={handleHideStory}
      story_id={story?.story_id}
    />
  </>
  )
}

const ProfileHeaderUser = ({ profile_data, isLoading = false }) => {

  const { id } = useParams();
  const [isSelf, setIsSelf] = useState(false);

  useEffect(() => {
    if (id == window.localStorage.getItem("user_id")) {
      setIsSelf(() => true);
    }

    window.document.title = `${profile_data.displayname ? profile_data.displayname : "Profile"} - XAPiT`;
  }, [profile_data]);

  return (
    <div className="profile-header-user">
      <div className="profile-header-user-avatar">
        <div className="profile-header-user-avatar-img">
          {isLoading ? (
            <LoadingCircleImage />
          ) : (
            <ProfileImage
              src={
                profile_data && profile_data.image_profile
                  ? profile_data.image_profile
                  : null
              }
              user_id={profile_data.user_id}
            />
          )}
        </div>
      </div>

      <div className="profile-header-user-info">
        <h2 className="profile-header-user-info-name">
          {profile_data.displayname}
        </h2>
        <ProfileHeaderUserActions isSelf={isSelf} profileData={profile_data} />
      </div>
    </div>
  );
};

const ProfileHeader = ({ profile_data, isLoading = false }) => {
  const { id } = useParams();
  useEffect(() => {
    console.log("Profile Header: ", profile_data); 
  }, [profile_data]);

  const isSelf = id == window.localStorage.getItem("user_id");

  return (
    <div className="profile-header">
      <img
        className="profile-header-coverphoto"
        src={
          profile_data && profile_data.cover ? profile_data.cover : null
        }
        data-bs-toggle="modal"
        data-bs-target="#profile-header-coverphoto-image"
        onError={({ target }) => {
          target.onerror = null;
          target.src = "/no_profile_pic.png";
        }}
      ></img>
      <div className="modal fade" id="profile-header-coverphoto-image" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-ex">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <img src={ profile_data && profile_data.cover ? profile_data.cover : null } className="d-block w-100"></img>
            </div>
          </div>
        </div>
      </div>

      <ProfileHeaderUser profile_data={profile_data} isLoading={isLoading} />
      {isSelf && <UploadCoverPhoto subject_id={profile_data.user_id} subject_type={"user"}/>}
    </div>
  );
};

const NoPermissionError = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const handleHide = () => {
    setShow(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Modal show={show} centered>
      <Modal.Body>You don't have permission to view this profile</Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button variant="secondary" onClick={handleBack}>
          {" "}
          Back{" "}
        </Button>
        <Button variant="danger" onClick={handleHide}>
          {" "}
          Close{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ProfileLayout = ({ children }) => {
  let { id } = useParams();
  const { data, isLoading } = useGetUserInfo({
    user_id: id,
  });

  const [unauthorized, setUnauthorized] = useState(false);
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (data && data.body && data.body.response) {

      setProfileData(() => data.body.response);
    }
    if (data && data.error_code === "unauthorized") {
      setUnauthorized(true);
    }
  }, [data]);

  return (
    <DefaultLayout>
      <div className="profile-container">
        <ProfileHeader profile_data={profileData} isLoading={isLoading} />
        <ProfileNav />
        <div className="profile-content-container">
          {unauthorized ? (
            <NoPermissionError />
          ) : (
            <>
              <ProfileSidebar
                profile_data={profileData}
                isLoading={isLoading}
              />
              {children}
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProfileLayout;
