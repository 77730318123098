import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Comments, CreateComment } from "../../components/posts/Comment";
import {
  likeFeedOrComment,
  unlikeFeedOrComment, useGetAdvancedActivityFeeds
} from "../../utils/api/functions/advancedActivityFeeds";

import { PostItem } from "../../components/posts/Feedv2";
import PostPrivacyIcon from "../../components/posts/PostPrivacyIcon";
import { PostFooter } from "../../components/posts/PostsFeed";
import { FeedProvider } from "../../utils/context/feedContext";
import { map_type_to_link } from "../../utils/mapping";

import "bootstrap/js/src/modal";

const _postLike = async (action_id, updated_object) => {
  let postData = {
    action_id: action_id,
  };
  const formData = new FormData();
  for (let key in postData) {
    formData.append(key, postData[key]);
  }
  const result = await likeFeedOrComment(formData);

  if (result.status_code == 204 || result.status_code == 200) {
    return updated_object;
  } else {
    throw new Error("Invalid API Response");
  }
};
const _postUnlike = async (action_id, updated_object) => {
  let postData = {
    action_id: action_id,
  };
  let formData = new FormData();
  for (let key in postData) {
    formData.append(key, postData[key]);
  }
  let res = await unlikeFeedOrComment(formData);
  if (res.status_code == 204 || res.status_code == 200) {
    return updated_object;
  } else {
    throw new Error("Invalid API Response");
  }
};

const PageAttachment = ({attachment}) => {

  const attachment_link = map_type_to_link(attachment.attachment_type, attachment.attachment_id)


  return(
    <div className="post-page-attachment">
      <a href={attachment_link}>
      <div className="post-page-attachment-image">
        <img src={attachment.image_main.src}/>
      </div>
      <div className="post-page-attachment-content">
        <div className="post-page-attachment-title">
          {attachment.title}
        </div>
        <div>
          {attachment.body}
        </div>
      </div>
      </a>
    </div>
  )
}

const PostAttachment = ({ attachment }) => {
  if (attachment?.attachment_type?.includes("activity_action")) {
    return (
      <PostItem
        postData={attachment.shared_post_data[0]}
        key={`post-item-attachment-${attachment.attachment_id}`}
        handles={{}}
        actionsOff={true}
        commentsOff={true}
      />
    );
  }

  if (attachment?.attachment_type?.includes("photo")) {
    return (
      <div className="post-page-item-container">
        <div>
          <img
          src={attachment.image_main.src}
          className="post-page-item-attachment-image"
          data-bs-toggle="modal"
          data-bs-target="#post-page-item-attachment-image"
          ></img>
        </div>
        <div className="modal fade" id="post-page-item-attachment-image" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <img src={attachment.image_main.src} className="d-block w-100"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (attachment?.attachment_type?.includes("video")) {
    return (
      <div className="post-item-attachment-video">
        <video autoPlay={false} controls>
          <source src={attachment.attachment_video_url} />
        </video>
      </div>
    );
  }
  if (attachment?.attachment_type?.includes("link")) {
    return (
      <div className="post-item-attachment-link">
        <a href={attachment.uri} target="_blank">
        <div className="post-item-attachment-link-image">
          <img src={attachment.image_main.src} />
        </div>
        <div className="post-item-attachment-link-content">
          <div className="post-item-attachment-link-title">
              {attachment.title}
          </div>
        </div>
        </a>
      </div>
    );
  }

  if (["sitepage_page", "group", "event"].includes(attachment?.attachment_type)) {
    return(
      <PageAttachment attachment={attachment} />
    )
  }

  return null;
};

const PostAttachmentCarousel = ({ attachments }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      variant="dark"
      className="post-item-attachment-carousel"
    >
      {attachments.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PostAttachment
                attachment={item}
                key={`post-item-attachment-${index}`}
              />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

const PostView = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetAdvancedActivityFeeds({
    action_id: id,
  });

  const [isLiked, setLiked] = useState(0);
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (data?.body?.data && data.body.data[0]) {
      
      setComments(() => data.body.comment_preview[0]);
      setPost(() => data.body.data[0].feed);
      setLiked(() => data.body.data[0].is_like);
    }
  }, [data]);

  useEffect(() => {}, [post]);

  let has_attachment = false;
  if (post?.attachment_count && post?.attachment_count > 0) {
    has_attachment = true;
  }

  const post_footer_handles = {
    handleLike: _postLike,
    handleUnlike: _postUnlike,
  };

  // Render message if post is not found
  
  if (!isLoading && Object.keys(post).length === 0) {
    return (
      <div className="post-page-container">
        <div className="post-page-content">
          <div className="loading-icon-wrapper">Post not found</div>
        </div>
      </div>
    );
  }

  return (
    <div className="post-page-container">
      <div className="post-page-content">
        {isLoading ? (
          <div className="loading-icon-wrapper">Loading...</div>
        ) : (
          <div
            className={`post-item ${has_attachment ? "has-attachment" : ""}`}
          >
            <div className="post-item-header">
              <img src={post?.subjectIformation?.image} />
              <div className="post-item-header-text">
                <h5 className="post-item-header-title">
                  <Link to={`/profile/${post.subjectIformation.subject_id}`}>
                    {post?.subjectIformation?.displayname}
                  </Link>
                </h5>
                <p className="post-item-header-date">
                  {post?.modified_date
                    ? new Date(post.modified_date).toLocaleString("default", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })
                    : ""}{" "}
                  <PostPrivacyIcon privacy_type={post?.privacy} />
                </p>
              </div>
            </div>
            <div className="post-item-body">
              {showMore ? post.body : `${post.body.slice(0, 250)}`}
              {post.body && post.body.length > 250 && (
                <button
                  className="show-more-less-btn"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? "Show less" : "Show more"}
                </button>
              )}
            </div>
            {has_attachment ? (
              post?.attachment?.length > 1 ? (
                <PostAttachmentCarousel attachments={post?.attachment} />
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PostAttachment attachment={post?.attachment[0]} />
                </div>
              )
            ) : null}
            <PostFooter
              is_like={isLiked}
              action_id={id}
              handles={post_footer_handles}
              handleCommentToggle={() => {}}
            />
            <div className="comment-container">
              <FeedProvider>
                {post.commentable === 1 ? (
                  <CreateComment action_id={id} />
                ) : null}

                <Comments action_id={id} comment_data={comments} />
              </FeedProvider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostView;
