// Stories component for profile page. 
import React, { useEffect } from "react";
import { useGetSitestories, useViewStory } from "../../../utils/api/functions/sitestories";
import CreateStory from "./CreateStory";

const Stories = () => {
    const {data, error} = useGetSitestories({limit: 10});
    const {data: viewdata, error: viewerror} = useViewStory({});
    useEffect(() => {
        console.log("Sitestory data: ", data); 
    }, [data])

    useEffect(() => {
        console.log("View story data: ", viewdata);
    }, [viewdata])

    return(
        <div>
            <CreateStory />
            Stories placeholder
        </div>
    )
}

export default Stories;