import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdvertisementStats from "../../components/charts/AdvertisementStats";
import {
  getChunkArrayItemIfExist, GridList
} from "../../components/lists/VerticalGrid";
import { useGetAds } from "../../utils/api/functions/communityAds";
const GUTTER_SIZE = 10;

const resource_type_to_url = {
  event: "/events/event",
  group: "/groups/group",
  sitepage: "/pages/page",
  profile: "/profile",
};

const StatsModal = ({ad_id, show, onHide}) => {
  return(
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Statistics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvertisementStats ad_id={ad_id}/>
      </Modal.Body>
    </Modal>
  )
}

const AdLink = ({ id, type, url, title }) => {
  if (type) {
    return (
      <Link to={`${resource_type_to_url[type]}/${id}`} target="_blank">
        {title}
      </Link>
    );
  }

  return (
    <a href={url} target="_blank">
      {title}
    </a>
  );
};

const AdItem = ({ style, data, columnIndex, rowIndex }) => {
  const [showStats, setShowStats] = useState(false);
  const [showMenu, setShowMenu] = useState(false); 
  let item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);
  console.log("Ad item: ", item_data)
  if (!item_data) return null;

  return (
    <div
      style={{
        ...style,
        left: style.left + GUTTER_SIZE,
        top: style.top + GUTTER_SIZE,
        width: style.width - GUTTER_SIZE,
        height: style.height - GUTTER_SIZE,
      }}
      className="ad-board-item"
    >  
      <div className="ad-board-item-title">
        <AdLink
          id={item_data.resource_id}
          type={item_data.resource_type}
          url={item_data.url}
          title={item_data.cads_title}
        />
        <div className="ad-board-item-options-menu-wrapper">
          <FontAwesomeIcon icon={faEllipsis}
            onClick={
              () => {setShowMenu(!showMenu)}
            }
          />
          <div className={`ad-board-item-options-menu ${showMenu ? 'show' : ''}`}>
            <div className="ad-board-menu-action"
              onClick={
                ()=>{
                  setShowStats(true); 
                  setShowMenu(false);
                }
              }
            >
              Statistics
            </div>
          </div>
        </div>
        
      </div>
      <img src={item_data.image} 
        onClick={
          () => setShowStats(true)
        }
      />
      <div className="ad-board-item-text">{item_data.cads_body}</div>
      <StatsModal ad_id={item_data.userad_id} show={showStats} onHide={
        () => setShowStats(false)
      }
      />
    </div>
  );
};

const AdBoard = () => {
  const { data } = useGetAds({});
  const [adData, setAdData] = useState([]);
  
  useEffect(() => {
    if (data && data.body && data.body.advertisements) {
      setAdData(data.body.advertisements);
    }
  }, [data]);

  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">Ad Board</div>
      <div className="box-content">
        <GridList items={adData} CellEl={AdItem} colCount={4} rowHeight={200} />
      </div>
    </div>
  );
};

export default AdBoard;
