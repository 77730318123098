import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Bars } from "react-loading-icons";
import { Link, useNavigate } from "react-router-dom";
import { getChunkArrayItemIfExist } from "../../components/lists/VerticalGrid";
import "../../styles/sass/components/Groups/MyGroups.scss";
import { useGetManagedGroups } from "../../utils/api/functions/groups";

const GUTTER_SIZE = 10;

const GroupListItem = ({ style, data, columnIndex, rowIndex }) => {
  const item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);

  const navigate = useNavigate();

  if (item_data) {
    return (
      <div
        className="groups-browse-content-item"
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
      >
        <div className="item-img-container">
          <img
            src={item_data.image_normal}
            onClick={() => {
              navigate(`/groups/group/${item_data.group_id}`);
            }}
          />
        </div>

        <div className="item-desc-container">
          <p className="item-name">
            <Link to={`/groups/group/${item_data.group_id}`}>
              {item_data.title}
            </Link>
          </p>
          <p className="item-owner">
            Led by{" "}
            <Link to={`/profile/${item_data.user_id}`}>
              <a className="item-owner-name">{item_data.owner_title}</a>
            </Link>
          </p>
        </div>
      </div>
    );
  }
  return null;
};

// export const GroupsList = ({ groups }) => {
//   return (
//     <GridList
//       items={groups}
//       colCount={1}
//       CellEl={GroupListItem}
//       autosizerClass=""
//       gridClassName=""
//       rowHeight={250}
//     />
//   );
// };

const Search = ({ handleSearch }) => {
  const [text, setText] = useState("");
  const [view, setView] = useState("");

  const handleSubmit = () => {
    
    

    handleSearch({
      text: text,
      view: view,
    });
  };

  return (
    <div className="groups-sidebar-search">
      <Form>
        <Form.Group>
          <Form.Label>Search Groups: </Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => {
              setText(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>View: </Form.Label>
          <Form.Select
            onChange={(e) => {
              setView(e.target.value);
            }}
          >
            <option value={1}>All Groups</option>
            <option value={2}>Groups I Lead</option>
          </Form.Select>
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Button variant="primary" onClick={handleSubmit}>
            Search
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const MyGroups = () => {
  const [values, setValues] = useState({
    text: "",
    view: 1,
  });
  const { data, isLoading } = useGetManagedGroups(values);

  const [groups, setGroups] = useState([]);

  //UseEffect to update on newly fetched data from BrowseGroups
  useEffect(() => {
    setGroups(() => []);
    if (data && data.body && data.body.response) {
      setGroups(() => [...data.body.response]);
    } else if (data && data.body && data.body.totalItemCount === 0) {
      setGroups(() => []);
    }
  }, [data]);

  const handleSearch = (_values) => {
    setValues(() => _values);
  };

  return (
    <div className="groups-my-groups-container">
      <div className="groups-my-groups-main-content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Bars stroke="#00BCD4" fill="#00BCD4" />
          </div>
        ) : null}
        <div className="groups-my-groups-content">
          {groups.length === 0 && !isLoading ? (
            <div style={{ display: "flex", justifyContent: "center"}}>
              No Groups Found
            </div>
          ) : null}
          {groups.map((val, index) => {
            return (
              <div
                className="groups-my-groups-content-item"
                key={`groups-my-groups-item-${index}`}
              >
              <div className="item-img-container">
                <Link to={`/groups/group/${val.group_id}`}>
                  <img src={val.image}/>
                </Link>
              </div>

              <div className="item-desc-container">
                <p className="item-name">
                  <Link to={`/groups/group/${val.group_id}`}>
                    {val.title}
                  </Link>
                </p>
                <p className="item-owner">
                  <span>{val.member_count} members </span>
                  led by{" "}
                  <Link to={`/profile/${val.user_id}`}>
                    <a className="item-owner-name">{val.owner_title}</a>
                  </Link>
                </p>
                <p className="group-item-description">
                  {val.description}
                </p>
              </div>
            </div>
            );
          })}
        </div>
        {/* <GroupsList groups={groups} /> */}
      </div>
      <div className="groups-my-groups-sidebar-content">
        <Search handleSearch={handleSearch} />
      </div>
    </div>
  );
};

export default MyGroups;
