import React from 'react';
//Routes settings

import { Navigate } from "react-router-dom";

//Import pages

import Home from "../pages/Home/Home.jsx";

//import Login from "../pages/Login/Login.jsx";
const Login = React.lazy(() => import("../pages/Login/Login.jsx"));

//import Xapit from "../pages/Xapit/Xapit.jsx";
const Xapit = React.lazy(() => import("../pages/Xapit/Xapit.jsx"));

import GroupsBrowse from "../pages/Groups/Browse.jsx";
import CreateGroup from "../pages/Groups/Create.jsx";
import GroupEdit from "../pages/Groups/GroupEdit/GroupEdit.jsx";
import MyGroups from "../pages/Groups/MyGroups.jsx";

//Group View Components
import GroupMembers from "../pages/Groups/GroupView/GroupMembers.jsx";
import GroupPosts from "../pages/Groups/GroupView/GroupPosts.jsx";

//Import Layouts
import DefaultLayout from "../layouts/Default";
import GroupsHomeLayout from "../layouts/GroupsHomeLayout";
import GroupViewLayout from "../layouts/GroupsViewLayout.jsx";

//Import Icons for nav items
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileLayout from "../layouts/ProfileLayout.jsx";
import { PageView } from "../pages/Pages/PageView/PageView.jsx";
import ProfileAlbums from "../pages/Profile/Albums.jsx";
import ProfileFriends from "../pages/Profile/Friends.jsx";
import ProfileInfo from "../pages/Profile/Info.jsx";
import ProfilePosts from "../pages/Profile/Posts.jsx";
import ProfileVideos from "../pages/Profile/Videos.jsx";

//Profile Editing
import ProfileEditLayout from "../layouts/ProfileEditLayout.jsx";
import ProfileSettingsLayout from "../layouts/ProfileSettingsLayout.jsx";
import EditProfileInfo from "../pages/Profile/Edit/ProfileInfo.jsx";
import EditProfilePhoto from "../pages/Profile/Edit/ProfilePhoto.jsx";

//Profile Settings imports
import ProfileSettingsAdvancedNotifications from "../pages/Profile/Settings/AdvancedNotifications.jsx";
import ProfileSettingsChangePassword from "../pages/Profile/Settings/ChangePassword.jsx";
import ProfileSettingsDeleteAccount from "../pages/Profile/Settings/DeleteAccount.jsx";
import ProfileSettingsGeneral from "../pages/Profile/Settings/General.jsx";
import ProfileSettingsNotifications from "../pages/Profile/Settings/Notifications.jsx";
import ProfileSettingsPrivacy from "../pages/Profile/Settings/Privacy.jsx";
import ProfileSettingsSubscription from "../pages/Profile/Settings/Subscription.jsx";

//Import Sitepages
import PageDashboardLayout from "../layouts/PageDashboardLayout.jsx";
import PagesLayout from "../layouts/PagesLayout.jsx";
import Invite from "../pages/Invite/Invite.jsx";
import Notifications from "../pages/Notifications/Notifications.jsx";
import CreatePage from "../pages/Pages/CreatePage.jsx";
import PageAdminApps from "../pages/Pages/Dashboard/Admin/Apps.jsx";
import PageAdminContact from "../pages/Pages/Dashboard/Admin/Contact.jsx";
import PageFeaturedAdmins from "../pages/Pages/Dashboard/Admin/FeaturedAdmins.jsx";
import PageAdminInsights from "../pages/Pages/Dashboard/Admin/Insights.jsx";
import PageManageAdmins from "../pages/Pages/Dashboard/Admin/ManageAdmins.jsx";
import PageAdminReports from "../pages/Pages/Dashboard/Admin/Reports.jsx";
import PageEditInfo from "../pages/Pages/Dashboard/EditInfo.jsx";
import PageOverview from "../pages/Pages/Dashboard/Overview.jsx";
import PagePhoto from "../pages/Pages/Dashboard/PagePhoto.jsx";
import PagePromotionMarketing from "../pages/Pages/Dashboard/Promotion/Marketing.jsx";
import MyPages from "../pages/Pages/MyPages.jsx";
import Sitepages from "../pages/Pages/Pages.jsx";

//Import Advertise
import AdvertiseLayout from "../layouts/AdvertiseLayout.jsx";
import AdBoard from "../pages/Advertise/AdBoard.jsx";
import AdCampaigns from "../pages/Advertise/AdCampaigns.jsx";
import AdHelp from "../pages/Advertise/AdHelp.jsx";
import AdReports from "../pages/Advertise/AdReports.jsx";
import Advertise from "../pages/Advertise/Advertise.jsx";
import CreateAd from "../pages/Advertise/CreateAd.jsx";
import Search from "../pages/Search/Search.jsx";

//import Signup from "../pages/Signup/Signup.jsx";
const Signup = React.lazy(() => import("../pages/Signup/Signup.jsx"));

//Import Events Page
import EventsLayout from "../layouts/EventsLayout.jsx";
import CreateEventPage from "../pages/Events/CreateEventPage.jsx";
import EventEdit from "../pages/Events/EventView/EventEdit.jsx";
import EventView from "../pages/Events/EventView/EventView.jsx";
import MyEvents from "../pages/Events/MyEvents.jsx";
import OngoingEvents from "../pages/Events/OngoingEvents.jsx";
import PastEvents from "../pages/Events/PastEvents.jsx";
import UpcomingEvents from "../pages/Events/UpcomingEvents.jsx";

//Import Post View
import NotFound from "../components/widgets/NotFound.jsx";
import Messages from "../pages/Messages/Messages.jsx";
import LikedPages from '../pages/Pages/LikedPages.jsx';
import PostView from "../pages/Posts/Posts.jsx";
import VerifyEmail from "../pages/Signup/VerifyEmail.jsx";

//Forgot Password page 
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword.jsx';
import Stories from '../pages/Profile/Stories/Stories.jsx';
import ResetPassword from '../pages/ResetPassword/ResetPassword.jsx';

//Nav Icons
const AngleDown = () => {
  return (
    <FontAwesomeIcon
      className="default-header-nav-dropdown-icon-animation"
      icon={solid("angle-down")}
    />
  );
};
const Ellipsis = () => {
  return <FontAwesomeIcon icon={solid("ellipsis")} />;
};

/**
 * Permission Types
 * none (no permission required)
 * member (default member)
 * owner (Owner of Object)
 * admin (Site admin)
 */
const route_config = [
  {
    name: "XAPiT",
    path: "/",
    route: true,
    exact: true,
    element: <Xapit />,
    level: "none",
  },
  {
    path: "/home",
    document_title: "Home",
    element: <Home />,
    level: "member",
    layout: <DefaultLayout />,
    route: true,
    nav: true,
    navOptions: {
      name: "My News Feed",
      link: "/home",
    },
  },
  {
    name: "Login",
    document_title: "Login",
    path: "/login",
    element: <Login />,
    level: "none",
    route: true,
    nav: false,
    navOptions: {
      name: "Login",
      link: "/login",
    },
  },
  {
    name: "Signup",
    document_title: "Signup",
    path: "/signup",
    element: <Signup />,
    level: "none",
    route: true,
    nav: false,
    navOptions: {
      name: "Signup",
      link: "/signup",
    },
  },
  {
    name: "SignupVerify",
    document_title: "Verify",
    path: "/signup/verify",
    element: <VerifyEmail />,
    level: "none",
    route: true,
    nav: false,
    navOptions: {
      name: "",
      link: "",
    },
  },
  {
    name: "ForgotPassword", 
    document_title: "Forgot Password", 
    path: "/forgot-password", 
    element: <ForgotPassword />, 
    level: "none", 
    route: true, 
    nav: false, 
    navOptions: {
      name: "", 
      link: ""
    }
  },
  {
    name: "ResetPassword", 
    document_title: "Reset Password", 
    path: "/reset-password/:code/:uid", 
    element: <ResetPassword />, 
    level: "none", 
    route: true, 
    nav: false, 
    navOptions: {
      name: "",
      link: ""
    }
  },
  {
    name: "Profile",
    document_title: "Profile",
    path: "/profile/:id",
    element: <Navigate replace to="posts" />,
    layout: <ProfileLayout />,
    level: "member",
    api_v1_item_type: "User",
    route: true,
    nav: true,
    navOptions: {
      name: "My Profile",
      link: "/profile/:id",
      icon: <AngleDown />,
      subMenu: [
        { link: "/profile/:id/posts", name: "Posts" },
        { link: "/profile/:id/info", name: "Info" },
        // { link: "/profile/:id/stories", name: "Stories" },
        { link: "/profile/:id/videos", name: "Videos" },
        { link: "/profile/:id/friends", name: "Friends" },
        { link: "/profile/:id/albums", name: "Albums" },
      ],
    },
    subRoutes: [
      {
        path: "/profile/:id/posts",
        element: <ProfilePosts />,
        layout: <ProfileLayout />,
      },
      {
        path: "/profile/:id/info",
        element: <ProfileInfo />,
        layout: <ProfileLayout />,
      },
      {
        path: "/profile/:id/stories",
        element: <Stories />,
        layout: <ProfileLayout />
      },
      {
        path: "/profile/:id/videos/:videoId?",
        element: <ProfileVideos />,
        layout: <ProfileLayout />,
      },
      {
        path: "/profile/:id/friends",
        element: <ProfileFriends />,
        layout: <ProfileLayout />,
      },
      {
        path: "/profile/:id/albums",
        element: <ProfileAlbums />,
        layout: <ProfileLayout />,
      },
    ],
  },
  {
    name: "ProfileEdit",
    document_title: "Edit Profile",
    path: "/profile/edit",
    element: <EditProfileInfo />,
    layout: <ProfileEditLayout />,
    level: "member",
    route: true,
    nav: false,
    navOptions: {
      name: "Edit Profile",
      link: "/profile/edit",
      subMenu: [
        { link: "/profile/edit", name: "Info" },
        { link: "/profile/edit/photo", name: "Photo" },
      ],
    },
    subRoutes: [
      {
        path: "profile/edit/photo",
        element: <EditProfilePhoto />,
      },
    ],
  },
  {
    name: "Settings",
    document_title: "Settings",
    path: "/profile/settings",
    element: <ProfileSettingsGeneral />,
    layout: <ProfileSettingsLayout />,
    level: "member",
    route: true,
    nav: false,
    navOptions: {
      name: "Settings",
      link: "/profile/settings",
      subMenu: [
        { link: "/profile/settings", name: "General" },
        { link: "/profile/settings/privacy", name: "Privacy" },
        { link: "/profile/settings/notifications", name: "Notifications" },
        { link: "/profile/settings/subscriptions", name: "Subscriptions" },
        { link: "/profile/settings/change-password", name: "Change Password" },
        { link: "/profile/settings/delete-account", name: "Delete Account" },
      ],
    },
    subRoutes: [
      {
        path: "/profile/settings/privacy",
        element: <ProfileSettingsPrivacy />,
      },
      {
        path: "/profile/settings/notifications",
        element: <ProfileSettingsNotifications />,
      },
      {
        path: "/profile/settings/advancednotifications",
        element: <ProfileSettingsAdvancedNotifications />
      },
      {
        path: "/profile/settings/subscriptions",
        element: <ProfileSettingsSubscription />,
      },
      {
        path: "/profile/settings/change-password",
        element: <ProfileSettingsChangePassword />,
      },
      {
        path: "/profile/settings/delete-account",
        element: <ProfileSettingsDeleteAccount />,
      },
    ],
  },
  {
    name: "Portals",
    document_title: "Portals",
    path: "/portals",
    level: "member",
    route: true,
    nav: true,
    navOptions: {
      name: "Portals",
      link: "/portals",
      icon: <AngleDown />,
      subMenu: [{ link: "/pages/page/12", name: "Home Schooling" }],
    },
  },
  {
    name: "Pages",
    document_title: "Pages",
    path: "/pages",
    element: <Sitepages />,
    layout: <PagesLayout />,
    level: "member",
    api_v1_item_type: "Sitepage",
    route: true,
    nav: true,
    subRoutes: [
      // {
      //     path: "/pages/browse",
      //     element: <PagesBrowse />,
      //     layout: <PagesLayout />
      // },
      {
        path: "/pages/my-pages",
        element: <MyPages />,
        layout: <PagesLayout />,
      },
      {
        path: "/pages/liked-pages",
        element: <LikedPages />,
        layout: <PagesLayout />
      },
      {
        path: "/pages/create",
        element: <CreatePage />,
        layout: <PagesLayout />,
      },
      {
        path: "/pages/page/:id",
        element: <PageView />,
        layout: <DefaultLayout />,
      },
    ],
    navOptions: {
      name: "Pages",
      link: "/pages",
      icon: <AngleDown />,
      subMenu: [
        { link: "/pages", name: "Home" },
        // {link: "/pages/browse", name: "Browse Pages"},
        { link: "/pages/my-pages", name: "My Pages" },
        { link: "/pages/liked-pages", name: "Liked Pages"},
        // {link: "/pages/pinboard", name: "Pinboard"},
        //{link: "/pages/claim", name: "Claim a Page"},
        //{link: "/pages/home-schooling", name: "Home Schooling"},
        { link: "/pages/create", name: "Create a Page" },
      ],
    },
  },
  {
    name: "PageDashboard",
    path: "/pages/page/:id/dashboard",
    level: "member",
    route: true,
    nav: false,
    layout: <PageDashboardLayout />,
    element: <PageEditInfo />,
    subRoutes: [
      //TODO: ADD ELEMENTS AND ROUTES FOR
      {
        path: "/pages/page/:id/dashboard/overview",
        element: <PageOverview />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/edit",
        element: <PageEditInfo />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/photo",
        element: <PagePhoto />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/contact",
        element: <PageAdminContact />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/apps",
        element: <PageAdminApps />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/insights",
        element: <PageAdminInsights />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/reports",
        element: <PageAdminReports />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/manage",
        element: <PageManageAdmins />,
        layout: <PageDashboardLayout />,
      },
      {
        path: "/pages/page/:id/dashboard/admin/featured",
        element: <PageFeaturedAdmins />,
        layout: <PageDashboardLayout />,
      },
      // {
      //     path: "/pages/page/:id/dashboard/promotion/announcement",
      //     element: <PagePromotionAnnouncements />,
      //     layout: <PageDashboardLayout />
      // },
      {
        path: "/pages/page/:id/dashboard/promotion/marketing",
        element: <PagePromotionMarketing />,
        layout: <PageDashboardLayout />,
      },
    ],
    navOptions: {
      name: "PageDashboard",
      link: "/pages/page/:id/dashboard",
      icon: "",
      subMenu: [
        // {
        //     link: "/pages/page/:id/dashboard/overview",
        //     name: "Overview",
        //     category: "content"
        // },
        {
          link: "/pages/page/:id/dashboard/edit",
          name: "Edit Info",
          category: "content",
        },
        {
          link: "/pages/page/:id/dashboard/photo",
          name: "Photo",
          category: "content",
        },
        {
          link: "/pages/page/:id/dashboard/admin/contact",
          name: "Contact",
          category: "admin",
        },
        // {
        //     link: "/pages/page/:id/dashboard/admin/apps",
        //     name: "Apps",
        //     category: "admin"
        // },
        {
          link: "/pages/page/:id/dashboard/admin/insights",
          name: "Insights",
          category: "admin",
        },
        // {
        //     link: "/pages/page/:id/dashboard/admin/reports",
        //     name: "Reports",
        //     category: "admin"
        // },
        // {
        //     link: "/pages/page/:id/dashboard/admin/featured",
        //     name: "Featured",
        //     category: "admin"
        // },
        {
          link: "/pages/page/:id/dashboard/admin/manage",
          name: "Manage Admins",
          category: "admin",
        },
        // {
        //     link: "/pages/page/:id/dashboard/promotion/announcement",
        //     name: "Announcements",
        //     category: "promotion"
        // },
        {
          link: "/pages/page/:id/dashboard/promotion/marketing",
          name: "Marketing",
          category: "promotion",
        },
      ],
    },
  },
  {
    name: "Groups",
    document_title: "Groups",
    path: "/groups",
    level: "member",
    api_v1_item_type: "Group",
    route: true,
    nav: true,
    layout: <DefaultLayout />,
    element: <Navigate replace to="browse" />,
    subRoutes: [
      {
        path: "/groups/group/:id",
        element: <Navigate replace to="posts" />,
        layout: <GroupViewLayout />,
      },
      {
        path: "/groups/group/:id/members",
        element: <GroupMembers />,
        layout: <GroupViewLayout />,
      },
      {
        path: "/groups/group/:id/posts",
        element: <GroupPosts />,
        layout: <GroupViewLayout />,
      },
      {
        path: "/groups/browse",
        element: <GroupsBrowse />,
        layout: <GroupsHomeLayout />,
      },
      {
        path: "/groups/my-groups",
        element: <MyGroups />,
        layout: <GroupsHomeLayout />,
      },
      {
        path: "/groups/create",
        element: <CreateGroup />,
        layout: <GroupsHomeLayout />,
      },
      {
        path: "/groups/group/:id/edit",
        element: <GroupEdit />,
        layout: <DefaultLayout />,
      },
    ],
    navOptions: {
      name: "Groups",
      link: "/groups",
      icon: <AngleDown />,
      subMenu: [
        { link: "/groups/browse", name: "Browse Groups" },
        { link: "/groups/my-groups", name: "My Groups" },
        { link: "/groups/create", name: "Create a Group" },
      ],
    },
  },
  // {
  //     name: "FAQS",
  //     path: "/faq",
  //     level: "member",
  //     route: true,
  //     nav: true,
  //     navOptions: {
  //         name: "FAQS",
  //         link: "/faq"
  //     }
  // },
  {
    name: "More",
    route: false,
    nav: true,
    subRoutes: [
      {
        path: "/advertise",
        element: <Advertise />,
        layout: <DefaultLayout />,
      },
    ],
    navOptions: {
      name: "More",
      icon: <Ellipsis />,
      subMenu: [
        // {link: "/videos", name: "Videos"},
        // {link: "/blogs", name: "Blogs"},
        { link: "/invite", name: "Invite" },
        { link: "/advertise", name: "Advertise" },
        { link: "/events", name: "Events" },
      ],
    },
  },
  {
    name: "Advertise",
    document_title: "Advertise",
    route: "/advertise",
    nav: false,
    level: "member",
    subRoutes: [
      {
        path: "/advertise",
        element: <CreateAd />,
        layout: <AdvertiseLayout />,
      },
      {
        path: "/advertise/board",
        element: <AdBoard />,
        layout: <AdvertiseLayout />,
      },
      {
        path: "/advertise/campaigns",
        element: <AdCampaigns />,
        layout: <AdvertiseLayout />,
      },
      {
        path: "/advertise/reports",
        element: <AdReports />,
        layout: <AdvertiseLayout />,
      },
      {
        path: "/advertise/help",
        element: <AdHelp />,
        layout: <AdvertiseLayout />,
      },
    ],
    navOptions: {
      name: "Advertising",
      subMenu: [
        { link: "/advertise/board", name: "Ad Board" },
        { link: "/advertise/campaigns", name: "My Campaigns" },
        { link: "/advertise", name: "Create an Ad" },
        { link: "/advertise/reports", name: "Reports" },
        { link: "/advertise/help", name: "Help" },
      ],
    },
  },
  {
    name: "Notifications",
    document_title: "Notifications",
    route: true,
    nav: false,
    path: "/notifications",
    element: <Notifications />,
    layout: <DefaultLayout />,
    navOptions: {
      name: "Notifications",
      link: "/notifications",
    },
    subRoutes: [],
  },
  {
    name: "Invite",
    document_title: "Invite",
    route: true,
    nav: false,
    path: "/invite",
    element: <Invite />,
    layout: <DefaultLayout />,
    navOptions: {
      name: "Invite",
      link: "/invite",
    },
    subRoutes: [],
  },
  {
    name: "Search",
    document_title: "Search",
    route: true,
    nav: false,
    path: "/search",
    element: <Search />,
    layout: <DefaultLayout />,
    navOptions: {
      name: "Search",
      link: "/search",
    },
  },
  // {
  //     name: "Videos",
  //     path: "/videos",
  //     element: <BrowseVideos />,
  //     layout: <VideosLayout />,
  //     route: true,
  //     nav: false,
  //     level: "member",
  //     subRoutes: [
  //         {
  //             path: "/videos/my-videos",
  //             element: <MyVideos />,
  //             layout: <VideosLayout />
  //         },
  //         {
  //             path: "/videos/browse-playlists",
  //             element: <BrowseVideoPlaylists />,
  //             layout: <VideosLayout />
  //         },
  //         {
  //             path: "/videos/create",
  //             element: <CreateVideo />,
  //             layout: <VideosLayout />
  //         },
  //         {
  //             path: "/videos/categories",
  //             element: <VideoCategories />,
  //             layout: <VideosLayout />
  //         }
  //     ],
  //     navOptions: {
  //         name: "Videos",
  //         link: "/videos",
  //         subMenu: [
  //             {link: "/videos", name: "Browse Videos"},
  //             {link: "/videos/my-videos", name: "My Videos"},
  //             {link: "/videos/browse-playlists", name: "Browse Playlists"},
  //             {link: "/videos/create", name: "Create a Video"},
  //             {link: "/videos/categories", name: "Categories"}
  //         ]
  //     }
  // },
  {
    name: "Events",
    document_title: "Events",
    path: "/events",
    element: <UpcomingEvents />,
    layout: <EventsLayout />,
    route: true,
    nav: false,
    level: "member",
    subRoutes: [
      {
        path: "/events",
        element: <UpcomingEvents />,
        layout: <EventsLayout />,
      },
      {
        path: "/events/ongoing", 
        element: <OngoingEvents />, 
        layout: <EventsLayout />
      },
      {
        path: "/events/past",
        element: <PastEvents />,
        layout: <EventsLayout />,
      },
      {
        path: "/events/my-events",
        element: <MyEvents />,
        layout: <EventsLayout />,
      },
      {
        path: "/events/create",
        element: <CreateEventPage />,
        layout: <EventsLayout />,
      },
      {
        path: "/events/event/:id",
        element: <EventView />,
        layout: <DefaultLayout />,
      },
      {
        path: "/events/event/:id/edit",
        element: <EventEdit />,
        layout: <DefaultLayout />,
      },
    ],
    navOptions: {
      name: "Events",
      link: "/events",
      subMenu: [
        { link: "/events", name: "Upcoming Events" },
        { link: "/events/ongoing", name: "Ongoing Events"},
        { link: "/events/past", name: "Past Events" },
        { link: "/events/my-events", name: "My Events" },
        { link: "/events/create", name: "Create New Event" },
      ],
    },
  },
  {
    name: "Posts",
    path: "/posts/:id",
    element: <PostView />,
    layout: <DefaultLayout />,
    level: "member",
    route: true,
    nav: false,
    navOptions: {
      name: "Posts",
      link: "/posts/:id",
    },
    subRoutes: [],
  },
  {
    name: "Messages",
    document_title: "Messages",
    path: "/messages/:id?",
    element: <Messages />,
    layout: <DefaultLayout />,
    level: "member",
    route: true,
    nav: false,
    navOptions: {
      name: "Messages",
      link: "/messages/:id",
    },
    subRoutes: [],
  },
  {
    name: "404",
    document_title: "404",
    path: "*",
    element: <NotFound />,
    layout: <DefaultLayout />,
    level: "none",
    route: true,
    nav: false,
    subRoutes: [],
  },
];
export default route_config;
