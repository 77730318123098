import {
  faComment,
  faShare,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  likeFeedOrComment,
  postHideFeedItem,
  unlikeFeedOrComment,
  usePaginateAdvancedActivityFeeds,
} from "../../utils/api/functions/advancedActivityFeeds";
//Feelings / Activities ID mapping
import feelingsConfig from "./postAttachments/activitiesConfig";

// Import comments component
import { Comments, CreateComment } from "./Comment";

//Import Share component
import Share from "./Share";

// Virtualized list imports
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ref } from "yup";

//Loading icon
import { useToast } from "rc-toastr";
import { useContext } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { postDeleteFeed } from "../../utils/api/functions/advancedActivityFeeds";
import { FeedContext } from "../../utils/context/feedContext";
import LoadingPostItem from "../loading-placeholders/LoadingPostItem";
import PostPrivacyIcon from "./PostPrivacyIcon";

import { map_type_to_link } from "../../utils/mapping";
import usePostsStore from "../../utils/stores/postsStore";
import VideoDisplay from "../videos/VideoDisplay";

const reactStringReplace = require("react-string-replace"); 

export const AttachmentCarousel = ({ attachments, owner_data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      variant="dark"
    >
      {attachments.map((item, index) => {
        return (
          <Carousel.Item key={index} style={{}}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PostItemAttachment
                attachment={item}
                key={`post-item-attachment-${index}`}
                owner_data={owner_data}
              />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

const PageAttachment = ({attachment}) => {




  const attachment_link = map_type_to_link(attachment.attachment_type, attachment.attachment_id)



  return(
    <div className="post-page-attachment">
      <a href={attachment_link}>
      <div className="post-page-attachment-image">
        <img src={attachment.image_main.src}/>
      </div>
      <div className="post-page-attachment-content">
        <div className="post-page-attachment-title">
          <span>{attachment.title}</span>
        </div>
        <div>
          {attachment.body}
        </div>
      </div>
      </a>
    </div>
  )
}

const PostItemAttachment = ({ attachment, owner_data }) => {


  const {handleShowAttachment} = useContext(FeedContext); 
  const handleShow = () => {
    setShowLightbox(true); 
  }
  const handleHide = () => {
    setShowLightbox(false); 
  }

  if (attachment?.attachment_type?.includes("activity_action")) {
    return (
      <PostItem
        postData={attachment.shared_post_data[0]}
        key={`post-item-attachment-${attachment.attachment_id}`}
        handles={{}}
        actionsOff={true}
        commentsOff={true}
      />
    );
  }

  if (attachment?.attachment_type?.includes("photo")) {
    return (
      <>
        <img
          onClick={() => {
            handleShowAttachment({
              video_data: attachment, 
              type: "album_photo", 
              subject_type: "album_photo", 
              subject_id: attachment.photo_id, 
              navButtons: false, 
              owner_data: owner_data, 
              attachment: true 
            })
          }}
          style={{
            cursor: "pointer"
          }}
          src={attachment.image_medium || attachment.image_main.src}
          className="post-item-attachment-image"
          alt=""
          loading="lazy"
        ></img>
        {/* <VideoDisplay 
          show={showLightbox}
          onHide={handleHide}
          video_data={attachment}
          type={"album_photo"}
          subject_type="album_photo"
          subject_id={attachment.photo_id}
          navButtons={false}
          owner_data={owner_data}
          attachment={true}
        /> */}
      </>
      
    );
  }
  if (attachment?.attachment_type?.includes("video")) {
     


    if(attachment.attachment_video_type === 1 || attachment.attachment_video_type === 6) {

      let video_url = attachment.attachment_video_url; 

      if(attachment.attachment_video_type === 1) {
        const queryParams = new URLSearchParams(video_url);
        queryParams.set("autoplay", "0"); 
        video_url = decodeURIComponent(queryParams.toString()); 
      }
      if(attachment.attachment_video_type === 6) {
        const parser = new DOMParser(); 
        let parse_iframe = parser.parseFromString(video_url, "text/html");
        let iframe_src = parse_iframe.querySelector("iframe").src;
        const queryParams = new URLSearchParams(iframe_src); 
        queryParams.get("autoplay") ? queryParams.set("autoplay", "0") : queryParams.append("autoplay", "0");
        video_url = queryParams.toString(); 
      }

      return (
        <div
          className="post-item-attachment-video"
        >
          {
            attachment.attachment_video_type === 6 ? (
              <iframe 
                srcDoc={attachment.attachment_video_url}
                loading="lazy"

              />
            ) : (
              <iframe 
                src={`https://`+video_url}
                loading="lazy"

              />
            )
          }
        </div>
      )
    }

    return (
      <div className="post-item-attachment-video">
        <div className="play-video-button"></div>
        <video autoPlay={false} onClick={(e)=>{e.preventDefault(); 
          handleShowAttachment({
            video_data: attachment, 
            type: "video", 
            subject_type: "video", 
            subject_id: attachment.photo_id, 
            navButtons: false, 
            owner_data: owner_data, 
            attachment: true
          })
        }}
          style={{
            cursor: "pointer"
          }}
        >
          <source src={attachment.attachment_video_url} />
        </video>
        {/* <VideoDisplay 
          show={showLightbox}
          onHide={handleHide}
          video_data={attachment}
          type={"video"}
          subject_type="video"
          subject_id={attachment.photo_id}
          navButtons={false}
          owner_data={owner_data}
          attachment={true}
        /> */}
      </div>
    );
  }
  if (attachment?.attachment_type?.includes("link")) {
    return (
      <div className="post-item-attachment-link">
        <a href={attachment.uri} target="_blank" rel="noreferrer">
        <div className="post-item-attachment-link-image">
          <img src={attachment.image_main.src} alt="" />
        </div>
        <div className="post-item-attachment-link-content">
          <div className="post-item-attachment-link-title">
              {attachment.title}
          </div>
        </div>
        </a>
      </div>
    );
  }
  if (["sitepage_page", "group", "event"].includes(attachment?.attachment_type)) {
    return(
      <PageAttachment attachment={attachment} />
    )
  }

  return null;
};

export const PostFooter = ({
  is_like,
  action_id,
  handles,
  handleCommentToggle,
  postData,
}) => {
  const [isLike, setLike] = useState(is_like ? true : false);

  const { handleLike, handleUnlike } = handles;

  return (
    <div className="row post-item-footer">
      <div
        className={isLike ? "color-blue" : ""}
        onClick={
          isLike
            ? () => {
                handleUnlike(action_id);
                setLike(false);
              }
            : () => {
                handleLike(action_id);
                setLike(true);
              }
        }
      >
        <FontAwesomeIcon icon={faThumbsUp} /> Like
      </div>
      {postData.can_comment === 1 && (
        <div onClick={handleCommentToggle}>
          <FontAwesomeIcon icon={faComment} /> Comment
        </div>
      )}
      {postData.can_share === 1 && (
        <div
          onClick={() => {
            handles.handleShowShare(postData);
          }}
        >
          <FontAwesomeIcon icon={faShare} /> Share
        </div>
      )}
    </div>
  );
};

const PostItemSettings = ({ action_id, action_type }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);
  const { handleDelete, handleHidePost } = useContext(FeedContext);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleDeletePost = async () => {
    handleDelete(action_id);
    setShow(false);
  };
  const _handleHidePost = async () => {
    handleHidePost(action_id, action_type);
    setShow(false); 
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="col-1 post-item-more-btn-container">
      <span
        onClick={toggleMenu}
        style={{
          cursor: "pointer",
        }}
      >
        ...
      </span>
      <div
        className={`post-item-settings-menu ${show ? "show" : "noshow"}`}
        ref={menuRef}
      >
        <div onClick={_handleHidePost}>
          <span>Hide Post</span>
        </div>
        <div onClick={handleDeletePost}>
          <span>Delete Post</span>
        </div>
      </div>
    </div>
  );
};

const ActivityText = ({postData}) => {
  let activityText = "";

    if (postData.feed.params.feelings) {
      let parentId = postData.feed.params.feelings.parent;
      let childId = postData.feed.params.feelings.child;
      let parentText = postData.feed.params.feelings.parenttitle;
      let childText = "";

      for (let i = 0; i < feelingsConfig.child[String(parentId)].length; i++) {
        let curItem = feelingsConfig.child[String(parentId)][i];
        if (curItem.id === childId) childText = curItem.title;
        else continue;
      }

      activityText = `is ${parentText} ${childText}`;
    }
    if (
      postData.feed.action_type_body.includes("{item:$subject}") &&
      postData.feed.action_type_body.includes("{item:$object}")
    ) {
      activityText = postData.feed.action_type_body
        .replace("{item:$subject}", "")
        .replace("{body:$body}", "")
        .replace(".", "");
      for (let i = 0; i < postData.feed.action_type_body_params.length; i++) {
        
        let current_param = postData.feed.action_type_body_params[i];
        if (current_param.search === "{item:$object}"){
          // activityText = activityText.replace(
          //   "{item:$object}",
          //   (<a href={'home'}>{current_param.label}</a>),
          // );
          activityText = reactStringReplace(activityText, "{item:$object}", (match, i) => (<a href={map_type_to_link(current_param.type, current_param.id)}>{current_param.label}</a>));
        }
        if (current_param.search === "{var:$type}"){
          activityText = reactStringReplace(activityText, "{var:$type}", (match, i) => (current_param.label)); 
        }
          // activityText = activityText.replace(
          //   "{var:$type}",
          //   current_param.label,
          // );
          
      }
    }
    if(activityText){
      return (
        <span>
          {activityText}
        </span>
      )
    }
    else {
      return null 
    }
    
}

export const PostItem = React.forwardRef(
  ({ postData, handles, commentsOff = false, actionsOff = false }, ref) => {
    const [showCreateComment, toggleComment] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    //Parse Date into more readable format
    let posted_date = new Date(postData.feed.modified_date);
    posted_date = posted_date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    let attachment_exists = postData.feed.attachment_count > 0;

    let tagged_friends_text = null;

    if (postData.feed.tags && postData.feed.tags.length > 0) {
      // let display_names = postData.feed.tags.map((item) => {
      //   return item.tag_obj.displayname;
      // });
      tagged_friends_text = (
        <>
        {
          postData.feed.tags.map((tag_item) => {
           
            return(
              <div style={{ display: 'inline' }}>
                <span>tagged </span>
                <a href={map_type_to_link(tag_item.tag_type, tag_item.tag_id)}>
                  <span> {tag_item.tag_obj.displayname}</span>
                </a>
              </div>
            )
          })
        }
        </>
      )

      // if (display_names.length === 1) {
      //   tagged_friends_text = display_names[0];
      // } else if (display_names.length === 2) {
      //   tagged_friends_text = display_names[0] + " and " + display_names[1];
      // } else {
      //   tagged_friends_text = "with " + display_names.join(", ");
      // }
    }

    

    const handleCommentToggle = () => {

      toggleComment(!showCreateComment);
    };

    useEffect(() => {
      // Check ownership
      const loggedInUserId = window.localStorage.getItem("user_id");
      if (
        postData?.feed?.subject_type === "user" &&
        postData?.feed?.subject_id == loggedInUserId
      ) {
        setIsOwner(true);
      }

      // Check comment permission
      if (!postData?.canComment) {
        toggleComment(false);
      }
    }, []);

    return (
      <div className="post-item-container" ref={ref} key={`post-${postData.feed.action_id}`}>
        <div className="post-item col">
          <div className="post-item-header row">
            <div className="col-1">
              <Link
                to={`/profile/${postData.feed.subjectIformation.subject_id}`}
                reloadDocument
              >
                <img
                  src={postData.feed.feed_icon}
                  className="post-item-profile-pic"
                  alt=""
                  onError={({ target }) => {
                    target.onerror = null 
                    target.src = "/no_profile_pic.png"
                  }}
                ></img>
              </Link>
            </div>
            <div className="col-8 post-item-info">
              <h5 className="post-item-display-name">
                <Link
                  to={`/profile/${postData.feed.subjectIformation.subject_id}`}
                  reloadDocument
                >
                  {postData.feed.subjectIformation.displayname}
                </Link>
                {postData.feed.action_type_body ? (
                  <span className="post-item-sub-text">
                    {" "}
                    {<ActivityText postData={postData}/>} {tagged_friends_text}
                  </span>
                ) : null}
              </h5>
              <p className="post-item-posted-date">
                <Link to={`/posts/${postData.feed.action_id}`}>
                  {posted_date}
                </Link>{" "}
                <PostPrivacyIcon privacy_type={postData.feed.privacy} />
              </p>
            </div>
            {isOwner ? (
              <PostItemSettings action_id={postData.feed.action_id} action_type={postData.feed.type}/>
            ) : null}
          </div>
          <div className="post-body-container">
            {showMore
              ? postData.feed.body
              : `${postData.feed.body.slice(0, 250)}`}
            {postData.feed.body && postData.feed.body.length > 250 && (
              <button
                className="show-more-less-btn"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "Show more"}
              </button>
            )}
          </div>
          {attachment_exists ? (
            postData.feed.attachment.length > 1 ? (
              <AttachmentCarousel attachments={postData.feed.attachment} owner_data={postData.feed.subjectIformation}/>
            ) : (
              <div className="post-body-container-content">
                <PostItemAttachment attachment={postData.feed.attachment[0]} owner_data={postData.feed.subjectIformation}/>
              </div>
            )
          ) : null}
          {actionsOff ? null : (
            <PostFooter
              is_like={postData.is_like}
              action_id={postData.feed.action_id}
              postData={postData}
              action_type={postData.feed.type}
              handles={handles}
              handleCommentToggle={handleCommentToggle}
            />
          )}
        </div>
        {commentsOff ? null : (
          <div className="comment-container">
            {showCreateComment && postData.can_comment === 1 ? (
              <CreateComment action_id={postData.feed.action_id} />
            ) : null}
            <Comments
              action_id={postData.feed.action_id}
              comment_data={postData.comment_data}
            />
          </div>
        )}
      </div>
    );
  },
);

const VirtualizedFeedList = ({
  postData,
  handles,
  loadMoreItems,
  isLoadingMore,
  CreatePostEl = null,
  _isLoading=false,
  ...props
}) => {
  const listRef = useRef({});
  const rowHeights = useRef({});
  const infiniteRef = useRef({});
  const setPostsListRef = usePostsStore((state) => state.setPostsListRef);
  const getRowHeight = (index) => {
    return rowHeights.current[index] + 8 || 80;
  };

  const setRowHeight = (index, size) => {
    rowHeights.current = { ...rowHeights.current, [index]: size };

    listRef.current.resetAfterIndex(0);
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current !== null) {
        const observer = new ResizeObserver(() => {
          if (rowRef.current === null) return;
          setRowHeight(index, rowRef.current.clientHeight);
        });

        observer.observe(rowRef.current);
        return () => ref.current && observer.unobserve(rowRef.current);
      }
    }, []);

    let postElement;

    if (!_isItemLoaded(index)) {
      if(!isLoadingMore && !_isLoading){
        postElement = (
          <div className="post-item-container-loading" ref={rowRef} key={`post-item-${index}`}>
             No more posts to load
          </div>
        )
      }
      else {
        postElement = (
          <div className="post-item-container-loading" ref={rowRef} key={`post-item-${index}`}>
            <LoadingPostItem />
          </div>
        );
      }
      
    } else if (index === 0 && CreatePostEl) {
      postElement = (
        <div ref={rowRef} key={`post-item-${index}`}>
          <CreatePostEl />
        </div>
      );
    } else {
      postElement = (
        <PostItem
          ref={rowRef}
          postData={postData[index]}
          key={`post-item-${index}`}
          handles={handles}
          style={style}
        />
      );
    }

    return (
      <div style={{ ...style }} key={"post-item-container-" + index}>
        {postElement}
      </div>
    );
  };

  const _itemCount = postData.length + 1;
  const _isItemLoaded = (index) => index < postData.length;

  // loadMoreItems wrapper to debounce parallel requests
  const _loadMoreItems = isLoadingMore ? () => {} : loadMoreItems;

  useEffect(() => {
    if (postData.posts && postData.length > 0) {
    }
  }, []);
  useEffect(() => {

  }, [postData]);

  useEffect(() => {
    let isMounted = true; 

    const updateRef = async() => {
      try{
        if(isMounted){
          setPostsListRef(listRef); 
        }
      }
      catch(error) {
        console.error("Error setting posts list ref: ", error);
      }
    }
    updateRef(); 
    return () => {
      isMounted = false;
    }

  }, [setPostsListRef])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          ref={infiniteRef}
          isItemLoaded={_isItemLoaded}
          itemCount={_itemCount}
          loadMoreItems={_loadMoreItems}
          threshold={5}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className="posts-list virtual-list-no-scrollbars"
              height={height}
              width={width}
              itemCount={_itemCount}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              ref={(el) => {
                ref(el);
                listRef.current = el;
              }}
              overscanCount={19}
              {...props}
            >
              {Row}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

const PostsFeed = ({
  feed_params = {},
  CreatePostComponent = null,
  can_post = true,
  ...props
}) => {
  //const {data, mutate, isLoading} = useGetAdvancedActivityFeeds(feed_params);

  //Default Feed Params
  if (!feed_params?.limit) {
    feed_params.limit = 10;
  }

  // if(!feed_params?.feed_type){

  //     feed_params.filter_type = "all";
  // }

  const { data, isLoading, mutate, size, setSize } =
    usePaginateAdvancedActivityFeeds(feed_params);

  const [postsData, setPostsData] = useState();
  const [postsError, setPostsError] = useState();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { toast } = useToast();

  const [showShare, setShowShare] = useState(false);
  const [shareData, setShareData] = useState({});


  const [attachmentLightboxData, setAttachmentLightboxData] = useState({}); 
  const [showAttachmentLightbox, setShowAttachmentLightbox] = useState(false); 

  //Set CreatePostComponent
  const CreatePostEl = () => {
    if (!can_post) return null;
    if (CreatePostComponent) {
      if (feed_params.subject_type && feed_params.subject_id) {
        return (
          <CreatePostComponent
            callMutate={mutate}
            subject_type={feed_params.subject_type}
            subject_id={feed_params.subject_id}
          />
        );
      }

      return <CreatePostComponent callMutate={mutate} />;
    }
    return null;
  };
  const likeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await likeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const unlikeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await unlikeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const handleLike = async (action_id) => {
    let updateObject = new Array(...data);

    for (let i = 0; i < updateObject.length; i++) {
      let page_data = updateObject[i];
      for (let x = 0; x < page_data.body.data.length; x++) {
        let item = page_data.body.data[x];
        if (item.feed.action_id == action_id) {
          item.is_like = 1;
          break;
        }
      }
    }

    await mutate(likeFn(action_id, updateObject), {
      optimisticData: updateObject,
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleUnlike = async (action_id) => {
    let updateObject = new Array(...data);

    for (let i = 0; i < updateObject.length; i++) {
      let page_data = updateObject[i];
      for (let x = 0; x < page_data.body.data.length; x++) {
        let item = page_data.body.data[x];
        if (item.feed.action_id == action_id) {
          item.is_like = 0;
          break;
        }
      }
    }

    await mutate(unlikeFn(action_id, updateObject), {
      optimisticData: updateObject,
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleShowShare = (data = null) => {

    setShowShare(true);
    if (data) {
      setShareData(() => data);
    }
  };
  const handleHideShare = () => {

    setShowShare(false);
  };
  const postHandles = {
    handleLike: handleLike,
    handleUnlike: handleUnlike,
    handleShowShare: handleShowShare,
  };
  const _loadMoreItemsA = async () => {
    if (isLoading) return;
    setIsLoadingMore(true);
    await setSize(size + 1);
    setIsLoadingMore(false);
  };
  useEffect(() => {
    const parseData = () => {
      setPostsError(""); // Clear any previous errors

      if (data) {

        let fetched_posts = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].status_code === 200) {
            //Set loading more false if there is no more data 
            if(data[i]?.body?.activityCount === 0){
              setIsLoadingMore(false); 
            }

            //Sort comment previews into post data
            let parsed_actions = [];
            let comment_preview = data[i].body.comment_preview;
            if (data[i]?.body?.data?.length > 0) {
              parsed_actions = data[i].body.data.map((val) => {
                let current_action_id = val.feed.action_id;
                let comment_data = {};
                comment_preview.map((val) => {
                  if (val.action_id === current_action_id) {
                    comment_data = val;
                  }
                });

                val.comment_data = comment_data;
                return val;
              });
            }

            fetched_posts.push(...parsed_actions);
          } else if (data[i].status_code === 401) {
            setPostsError(data[i].error_code);
          }
        }
        if (fetched_posts.length === 0) return; 
        setPostsData({posts: fetched_posts})
      }
    };
    parseData();
  }, [data]);

  
  if (isLoading) { //Changed from !postsData && isLoading
    return (
      <>
        <CreatePostEl />
        <LoadingPostItem />
      </>
    );
  }

  if (postsError === "unauthorized") {
    return (
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        You don't have permission to view this feed.
      </div>
    );
  }

  if (!postsData || postsData.length === 0) {
    return (
      <>
        <CreatePostEl />
        <div className="posts-feed col">
          <div
            style={{
              textAlign: "center",
              paddingTop: "1.5rem",
            }}
          >
            No posts here yet...
          </div>
        </div>
      </>
    );
  }

  const handleUpdate = () => {
    mutate();
  };
  const handleDelete = async (action_id) => {
    let res = await postDeleteFeed({ action_id: action_id });

    if (res.status_code === 204) {
      toast.success("Post deleted successfully");
    }
    mutate();
  };
  const handleHidePost = async(action_id, type) => {
    let res = await postHideFeedItem({id: action_id, type: 'activity_action'})

    if(res.status_code === 204){
      toast.success("Post hidden successfully")
    }
    mutate(); 

  };


  const handleShowAttachment = ({...params}) => {
    setAttachmentLightboxData(()=>params)
    setShowAttachmentLightbox(true); 
  }
  const handleHideAttachment = () => {
    setShowAttachmentLightbox(false); 
    setAttachmentLightboxData(()=>{})
  }

  return (
    <>
      <FeedContext.Provider
        value={{ handleUpdate, handleDelete, handleHidePost, handleShowAttachment }}
      >
        <div className="posts-feed col">
          <VirtualizedFeedList
            postData={[{}].concat(postsData.posts)}
            handles={postHandles}
            loadMoreItems={_loadMoreItemsA}
            isLoadingMore={isLoadingMore}
            CreatePostEl={CreatePostEl}
            _isLoading={isLoading}
            {...props}
          />
          {/* SHOW LIGHTBOX FOR ATTACHMENTS */}
          
          <VideoDisplay 
            show={showAttachmentLightbox}
            onHide={handleHideAttachment}
            {...attachmentLightboxData}
          />
           
          <Share show={showShare} onHide={handleHideShare} data={shareData} />
        </div>
      </FeedContext.Provider>
    </>
  );
};

export default PostsFeed;
