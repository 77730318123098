import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/js/src/modal";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UploadCoverPhoto from "../../../components/buttons/UploadCoverPhoto";
import ShareUserContent from "../../../components/widgets/ShareUserContent";
import { postLike, postUnlike } from "../../../utils/api/functions/likesComments";
import { postFollowPage, useGetSitePage } from "../../../utils/api/functions/sitepages";
import { PageViewContent } from "./PageViewContent";

const PageHeader = ({
  pageImage,
  coverImage,
  title,
  like_count,
  member_count,
  member_type,
  isOwner = false, 
}) => {

  const {id} = useParams(); 
  const {data, mutate} = useGetSitePage(id, {});
  const [followed, setFollowed] = useState(false); 
  const [liked, setLiked] = useState(false); 

  useEffect(() => {
    window.document.title = `${title ? title : "Page"} - XAPiT`;
  }, [title]);

  useEffect(() => {
    if(data && data.body){
      setFollowed(data.body.isPageFollowed);
      setLiked(data.body.is_like); 
    }
  }, [data])

  const {toast} = useToast();

  const handleToggleFollow = async() => {
    const res = await postFollowPage(id); 
    mutate({
      ...data,
      body: {
        ...data.body, 
        isPageFollowed: !followed
      }
    })
    if(res.status_code === 204) {
      toast.success("Page followed!"); 
    }
  }

  const handleLike = async() => {
    const res = await postLike({
      subject_id: id, 
      subject_type: "sitepage_page", 
    })
    mutate({
      ...data,
      body: {
        ...data.body, 
        is_like: true
      }
    })
  }
  const handleUnlike = async() => {
    const res = await postUnlike({
      subject_id: id, 
      subject_type: "sitepage_page"
    })
    mutate({
      ...data,
      body: {
        ...data.body, 
        is_like: false
      }
    })
  }

  const handleToggleLike = () => {
    if(liked) {
      handleUnlike(); 
    }
    else {
      handleLike(); 
    }
  }

  return (
    <div className="sitepage-header">
      <div className="sitepage-header-wrapper" style={{position: 'relative'}}>
        {isOwner ? <UploadCoverPhoto type="page" subject_id={id} subject_type={"sitepage_page"}/> : ""}
        <img
        src={coverImage}
        className="sitepage-header-coverphoto"
        data-bs-toggle="modal"
        data-bs-target="#sitepage-image-coverphoto"
        ></img>
        <div className="modal fade" id="sitepage-image-coverphoto" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <img src={coverImage} className="d-block w-100"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="sitepage-image-sm">
          <div className="sitepage-image-container">
            <img 
            src={pageImage}
            data-bs-toggle="modal"
            data-bs-target="#sitepage-image-pfp"
            ></img>
            <div className="modal fade" id="sitepage-image-pfp" tabIndex="-1" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-body">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <img src={pageImage} className="d-block w-100"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sitepage-header-box">
      <div className="sitepage-header-buttons">
        {
          isOwner ? null : (
              <>
              <button className="xapit-utility" onClick={handleToggleLike}> <FontAwesomeIcon icon={faThumbsUp} /> {liked ? "Unlike" : "Like"}</button>
              <button className="xapit-utility" onClick={handleToggleFollow}>{followed ? "- Unfollow" : "+ Follow"}</button>
              {/* <button className="xapit-utility">+ Join page</button> */}
              </>
          )
        }
        <SharePage page_data={data}/>
        </div>
        
        <div className="sitepage-header-text">
          <h4>{title}</h4>
          <p>
            {like_count} likes · {member_count} {member_type}
          </p>
        </div>
      </div>
    </div>
  );
};

const SharePage = ({page_data}) => {
  const {id} = useParams(); 
  const [showShare, setShowShare] = useState(false); 
  const handleShowShare = () => {
    setShowShare(true);
  }
  const handleHideShare = () => {
    setShowShare(false); 
  }

  return(
    <button className="xapit-utility" onClick={handleShowShare}>
      Share
      <ShareUserContent
        show={showShare}
        onHide={handleHideShare}
        data={page_data}
        type={"sitepage_page"}
        object_id={id}
        />
    </button>
  )
}

export const PageView = () => {
  const { id } = useParams();
  const { data } = useGetSitePage(id, {});
  const [pageData, setData] = useState({});

  useEffect(() => {
    if (data && data.body) {
      let new_obj = { ...data.body };

      setData(() => new_obj);
    }
  }, [data]);

  return (
    <div className="sitepage-view-container">
      <div className="sitepage-view-wrapper">
        <PageHeader
          coverImage={pageData.cover_image}
          pageImage={pageData.image_profile}
          title={pageData.title}
          like_count={pageData.like_count}
          member_count={pageData.member_count}
          member_type={pageData.member_title}
          isOwner={pageData.isOwner}
        />
        <PageViewContent subject_id={id} page_data={pageData} />
      </div>
    </div>
  );
};
