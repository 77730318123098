import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;


export const useGetSitestories = (values) => {
    return useSWR(
        [
          hostname + "/advancedactivity/stories/browse",
          {
            method: "GET",
            body: queryString.stringify(values),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              oauth_consumer_key: oauthConfig.oauth_consumer_key,
              oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
            },
          },
        ],
        fetcher,
      );
}

export const useViewStory = (story_id) => {
    return useSWR([
        hostname + "/advancedactivity/story/view/" + story_id, 
        {
            method: "GET", 
            headers: {
                "Content-Type": "application/json", 
                "Accept": "application/json", 
                "oauth_consumer_key": oauthConfig.oauth_consumer_key,
                "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
            }
        }
    ],
    fetcher
    )
}

export const postCreateSitestory = (values) => {
    return fetcher([
        hostname + "/advancedactivity/stories/create", 
        {
            method: "POST", 
            body: values, 
            headers: {
                "Accept": "application/json",
                "oauth_consumer_key": oauthConfig.oauth_consumer_key,
                "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
            },
        }
    ], false, true)
}

export const deleteSitestory = (story_id) => {
    return fetcher([
        hostname + "/advancedactivity/story/delete/" + story_id, 
        {
            method: "DELETE", 
            headers: {
                "Accept": "application/json",
                "oauth_consumer_key": oauthConfig.oauth_consumer_key,
                "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
            }
        }
    ], false, true)
}