import React from "react"
import ContentLoader from "react-content-loader"

const LoadingNotificationLarge = (props) => (
  <ContentLoader 
    speed={2}
    width={600}
    height={90}
    viewBox="0 0 600 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="51" cy="50" r="50" /> 
    <rect x="126" y="38" rx="0" ry="0" width="379" height="16" />
  </ContentLoader>
)

export default LoadingNotificationLarge

